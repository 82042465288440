import { createReducer } from "@reduxjs/toolkit";
import {
  fetchTranslationsRequest,
  fetchTranslationsSuccess,
  fetchTranslationsFailure,
} from "../actions";

interface TranslationsState {
  loading: boolean;
  translations: Record<string, string>;
  error: string | null;
}

const initialState: TranslationsState = {
  loading: false,
  translations: {},
  error: null,
};

const translationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTranslationsRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchTranslationsSuccess, (state, action) => {
      state.loading = false;
      state.translations = action.payload;
    })
    .addCase(fetchTranslationsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default translationsReducer;
