import React from "react";
import Translation from "@common/Translation";

const ProfileUpdatedComp = ({ handlePageRefresh }) => {
  return (
    <div>
      <h4 className="margin-bottom-md">
        {<Translation translate={"MYTD_profileUpdatedMsg"}></Translation>}
      </h4>
      <button className="btn btn-lg btn-primary" onClick={handlePageRefresh}>
        {<Translation translate={"MYTD_okLbl"}></Translation>}
      </button>
    </div>
  );
};
export default ProfileUpdatedComp;
