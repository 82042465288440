import React from "react";

const OrgUserCompCodeCheck = ({ codeChecking, codeMap, districtCodeData }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-4 col-md-offset-4">
        {!codeChecking && codeMap(districtCodeData)}
      </div>
    </div>
  );
};
export default OrgUserCompCodeCheck;
