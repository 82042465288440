import React from "react";
import Translation from "@common/Translation";

export const getShareRequestActionText = (actionCode) => {
  let actionText = "";
  switch (actionCode) {
    case "share_copy":
      actionText = <Translation translate="MYTD_hasSharedCopyMsg" />;
      break;
    case "share_sync":
      actionText = <Translation translate="MYTD_hasInvitedMsg" />;
      break;
    case "left_sync":
      actionText = <Translation translate="MYTD_hasLeftMsg" />;
      break;
    case "stop_sync":
      actionText = <Translation translate="MYTD_hasStoppedMsg" />;
      break;
    case "messagebundle_success":
      actionText = (
        <Translation translate="Bundle_Notification_Success" textonly={false} />
      );
      break;
    case "messagebundle_error":
      actionText = (
        <Translation
          translate="Bundle_Notification_failed"
          textonly={false}
          replaceArray={[
            "https://www.tobiidynavox.com/en-US/support-training/message-banking/",
            "https://www.tobiidynavox.com/en-US/about/contact/contact-tobii-dynavox-us/?redirect=true",
          ]}
        />
      );
      break;

    default:
      actionText = <Translation translate="MYTD_hasSharedMsg" />;
      break;
  }

  return actionText;
};

export const typeLabel = (product, item, translations) => {
  let itemType = "";
  switch (item) {
    case "backup":
    case "backups":
      itemType = translations.MYTD_BackupLbl;
      break;
    case "pageset":
    case "pagesets":
      itemType = translations.Pageset;
      break;
    case "book":
    case "books":
      itemType = translations.Books;
      break;
    case "student":
    case "students":
      itemType = translations.Students;
      break;
    default:
      itemType = item;
      break;
  }
  switch (product) {
    case "symbolmate":
      itemType = translations.Board;
      break;
    case "snapscene":
      itemType = translations.MYTD_BackupLbl;
      break;
    default:
      itemType;
      break;
  }

  return itemType ? itemType.toLowerCase() : "undefined";
};

/* eslint-disable react/jsx-key */
export const tableCols = {
  books: [
    <Translation translate="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_titleTxt" />,
    "",
  ],
  students: [
    <Translation translate="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_studentNameLbl" />,
    "",
  ],
  notifications: [
    <Translation translate="MYTD_dateLbl" />,
    <Translation translate="MYTD_detailsLbl" />,
    <Translation translate="MYTD_respondLbl" />,
  ],
  notificationHistory: [
    <Translation translate="MYTD_dateLbl" />,
    <Translation translate="MYTD_detailsLbl" />,
    <Translation translate="MYTD_tableHeadActionTaken" />,
  ],
};
