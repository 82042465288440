import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface TranslationProps {
  pre?: string;
  post?: string;
  textonly?: boolean;
  replaceArray?: string[];
  translate: string;
}

const Translation: React.FC<TranslationProps> = ({
  pre = "",
  post = "",
  textonly,
  replaceArray,
  translate,
}) => {
  const translationData = useSelector((state: RootState) => state.translations);
  const translation: string = translationData.translations?.[translate] || "";

  let passTranslation = translation || "";

  if (replaceArray && replaceArray.length) {
    replaceArray.forEach((item, idx) => {
      const replaceThis = `{${idx}}`;
      if (passTranslation.includes(replaceThis)) {
        passTranslation = passTranslation.replace(replaceThis, item);
      }
    });
  }

  if (textonly) {
    return translation ? <>{passTranslation}</> : null;
  } else {
    return translation ? (
      <span
        dangerouslySetInnerHTML={{
          __html: `${pre} ${passTranslation} ${post}`,
        }}
      />
    ) : null;
  }
};

export default Translation;
