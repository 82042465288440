import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import ProfileForm from "./ProfileForm";
import { fileUploadConfig } from "@helpers/fileUploadConfig";
import {
  checkBMODistCode,
  editProfile,
  deleteBMODistCode,
} from "@services/user-service";
import { setUserDetails } from "../../../../actions/userDetailsActions";
import { capitalizeFirstLetter } from "@helpers/utilities";

const format = fileUploadConfig.Avatar.Image;

const Profile = () => {
  const dispatch = useDispatch();
  const { translations } = useSelector((state) => state.translations);
  const { userDetail } = useSelector((state) => state.user);

  const [profile, setProfile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [avatarFileValid, setAvatarFileValid] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState({ zipCode: "" });
  const [codeChecking, setCodeChecking] = useState(false);
  const [codeDeleting, setCodeDeleting] = useState(false);
  const [districtCodeData, setDistrictCodeData] = useState("");
  const [codeValid, setCodeValid] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);

  useEffect(() => {
    if (userDetail) {
      setProfile(userDetail);
      if (userDetail.bmoDistrictCode) {
        handleDistCodeCheck(null, userDetail.bmoDistrictCode);
      }
    }
  }, [userDetail]);

  const toggleModal = useCallback(() => setShowModal((prev) => !prev), []);

  const handleTextUpdate = useCallback((e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => {
      const newProfile = _.cloneDeep(prevProfile || {});
      _.set(newProfile, name, value);
      return newProfile;
    });
  }, []);

  const handleDistCodeCheck = useCallback(async (e, code) => {
    e?.preventDefault();
    setCodeChecking(true);

    try {
      const res = await checkBMODistCode(code.toString());
      setCodeChecking(false);
      setCodeValid(res.isValid);
      setDistrictCodeData(res);
      if (!res.isValid) console.warn("Invalid code");
    } catch (error) {
      setCodeChecking(false);
      setDistrictCodeData({ code: "error" });
    }
  }, []);

  const handleDistCodeDelete = useCallback(
    async (e, code) => {
      e.preventDefault();
      await deleteBMODistCode(code);
      setCodeDeleting(true);
      try {
        setProfile((prev) => ({ ...prev, bmoDistrictCode: "" }));
        setCodeValid(false);
      } catch (error) {
        setDistrictCodeData(error);
      } finally {
        setCodeDeleting(false);
      }
    },
    [profile],
  );

  const validateField = useCallback(
    (fieldName, value) => {
      let isValid = true;
      let errorMessage = "";

      if (fieldName === "zipCode") {
        isValid = value && value.length > 0;
        errorMessage = isValid ? "" : translations.MYTD_zipRequiredMsg;
      }

      setFormErrorMessages((prev) => ({ ...prev, [fieldName]: errorMessage }));

      return isValid;
    },
    [translations],
  );

  const validateForm = useCallback(() => {
    const isZipCodeValid = validateField("zipCode", profile?.address?.zipCode);
    if (isZipCodeValid) {
      preparePayload();
    } else {
      window.scrollTo(0, 0);
    }
  }, [profile, validateField]);

  const preparePayload = useCallback(() => {
    if (!profile) return;

    const formData = new FormData();
    if (avatarFileValid) {
      const avatarFile = document.getElementById("addAvatar").files[0];
      if (avatarFile) {
        formData.append("avatar", avatarFile);
      }
    }

    Object.entries(profile).forEach(([key, value]) => {
      if (value && typeof value === "object") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue !== null) {
            formData.append(
              `${capitalizeFirstLetter(key)}.${capitalizeFirstLetter(subKey)}`,
              subValue,
            );
          }
        });
      } else if (value !== null && key !== "bmoDistrictCode") {
        formData.append(capitalizeFirstLetter(key), value);
      }
    });
    editProfile(formData);
    dispatch(setUserDetails(formData));
    setUpdateOk(true);
  }, [profile, avatarFileValid, dispatch]);

  const handleImageData = useCallback((fileName, data) => {
    const file = document.getElementById("addAvatar").files[0];
    const fileExt = file.name
      .substring(file.name.lastIndexOf("."))
      .toLowerCase();
    const validFormats = format.fileFormat.split(",").map((f) => f.trim());

    if (validFormats.includes(fileExt)) {
      setProfile((prev) => ({ ...prev, avatarUrl: data }));
      setAvatarFileValid(true);
    } else {
      handleAvatarError();
    }
  }, []);

  const handleFileInputChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) =>
          handleImageData(file.name, event.target.result);
        reader.readAsDataURL(file);
      }
    },
    [handleImageData],
  );

  const clearAvatarClick = useCallback(() => {
    setProfile((prev) => ({ ...prev, avatarUrl: "" }));
    setAvatarFileValid(false);
  }, []);

  const handleAvatarError = useCallback(() => {
    document.getElementById("avError").textContent =
      `${format.helpText} ${format.returnText}`;
  }, []);

  const handlePageRefresh = useCallback(() => window.location.reload(), []);

  return (
    profile && (
      <ProfileForm
        showModal={showModal}
        toggleModal={toggleModal}
        profile={profile}
        updateOk={updateOk}
        handleTextUpdate={handleTextUpdate}
        validateForm={validateForm}
        clearAvatarClick={clearAvatarClick}
        handleFileInputChange={handleFileInputChange}
        formErrors={formErrorMessages}
        handlePageRefresh={handlePageRefresh}
        avatarFileValid={avatarFileValid}
        handleDistCodeCheck={handleDistCodeCheck}
        handleDistCodeDelete={handleDistCodeDelete}
        codeChecking={codeChecking}
        codeDeleting={codeDeleting}
        codeValid={codeValid}
        districtCodeData={districtCodeData}
      />
    )
  );
};

export default Profile;
