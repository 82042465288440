import React, { ReactNode } from "react";

type AlertType = "success" | "danger" | "info" | "";

interface AlertProps {
  type: AlertType;
  children: ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type, children }) => {
  return (
    <div className={`alert alert-${type}`}>
      <h5>{children}</h5>
    </div>
  );
};

export default Alert;
