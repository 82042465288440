import React from "react";
import Translation from "@common/Translation";

const ProfileHeader = () => {
  return (
    <h2>
      <Translation translate="MYTD_updateProfileLbl"></Translation>
    </h2>
  );
};
export default ProfileHeader;
