import React from "react";
import Translation from "@common/Translation";
import DataSpinner from "@common/DataSpinner";

const DataStatusMessage = ({ isChecking, error }) => {
  if (isChecking) return <DataSpinner />;
  if (error) {
    return (
      <div className="container-fluid fade-block fade-in">
        <div className="margin-top-lg">
          <div className="alert alert-danger" role="alert">
            <Translation
              translate="An_error_occurred_while_processing_your_request_"
              textonly="true"
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default DataStatusMessage;
