import React from "react";
import Translation from "../Translation";
import EmailInputField from "./EmailInputField";

const EmailList = ({
  translations,
  email,
  setEmail,
  emailList,
  setEmailList,
}) => {
  const removeEmail = (emailToRemove) => {
    setEmailList((prevEmailList) =>
      prevEmailList.filter((email) => email !== emailToRemove),
    );
  };
  return (
    <div className="form-group">
      <EmailInputField
        translations={translations}
        email={email}
        setEmail={setEmail}
        emailList={emailList}
        setEmailList={setEmailList}
      />
      <div className="padding-top-none">
        {!!emailList.length && (
          <div style={{ marginTop: "10px" }}>
            <strong>
              <Translation translate="MYTD_shareFileWith" />
            </strong>
            <div>
              <ul className="list-unstyled row">
                {emailList.map((e, i) => (
                  <li key={`email_${i}`} className="col-md-12">
                    {e} -{" "}
                    <a href="#" onClick={() => removeEmail(e)}>
                      <Translation translate="MYTD_removeLbl" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailList;
