import React from "react";
import Translation from "@common/Translation";

const UpdateButton = ({ validateForm }) => {
  return (
    <div className="row">
      <div className="col-xs-12">
        <button
          className="btn btn-lg btn-primary"
          data-testid="updateProfileButton"
          onClick={validateForm}
        >
          {<Translation translate={"MYTD_updateProfileLbl"}></Translation>}
        </button>
      </div>
    </div>
  );
};
export default UpdateButton;
