import React, { useState } from "react";
import Translation from "@common/Translation";
import EmailInputField from "@common/EmailList/EmailInputField";

const SyncForm = ({
  itemOwner,
  setShowRemoveSyncModal,
  shareEmailList,
  isOwner,
  handleAddSync,
  handleRemoveSync,
  translations,
}) => {
  const emailsAlreadyInList = shareEmailList.map((item) => item.email);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState(emailsAlreadyInList);

  return (
    <tr>
      <td colSpan={2}>
        <hr />
        {isOwner && (
          <>
            <div className="margin-bottom-md">
              <h3>
                <Translation translate="Share_and_Sync" />
              </h3>
            </div>
            <p>
              <Translation translate="MYTD_pagesetSyncMsg1" />
            </p>
            <div className="form-group">
              <EmailInputField
                translations={translations}
                email={email}
                setEmail={setEmail}
                emailList={emailList}
                setEmailList={setEmailList}
                isOwner={isOwner}
                handleAddSync={handleAddSync}
              />
            </div>
          </>
        )}
        <div className="sync-form">
          {isOwner ? (
            <div>
              {shareEmailList.length > 0 && (
                <div className="padding-top-none margin-bottom-none well">
                  <strong>
                    <Translation translate="MYTD_pagesetSharedLbl" />
                  </strong>
                  <div>
                    <ul className="list-unstyled row">
                      {shareEmailList.map((list) => (
                        <li key={list.id} className="col-md-12">
                          {list.email} -
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveSync(list.id);
                            }}
                          >
                            <Translation translate="MYTD_stopSharingLbl" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="padding-top-none margin-bottom-none well">
              <div>
                <strong>
                  <Translation translate="Shared_By" />
                </strong>
                <div>
                  <ul className="list-unstyled row">
                    <li className="col-md-12">
                      {itemOwner} -{" "}
                      <a href="#" onClick={() => setShowRemoveSyncModal(true)}>
                        <Translation translate="MYTD_removeSyncLbl" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SyncForm;
