import React from "react";
import Translation from "@common/Translation";
import { useSelector } from "react-redux";

const ProfileAccountType = ({
  occupationTypeId,
  handleOccupationTypeChange,
}) => {
  const { translations } = useSelector((state) => state.translations);
  return (
    <div className="row">
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="accountType">
          <Translation translate={"MYTD_AcctTypeLbl"}></Translation>
        </label>{" "}
        <span className="optional">
          <Translation translate={"MYTD_optionalTxt"}></Translation>
        </span>
        <select
          className="form-control"
          value={occupationTypeId}
          onChange={handleOccupationTypeChange}
          id="occupationTypeId"
          name="occupationTypeId"
        >
          <option value="0">{translations.MYTD_selectUserTypeLbl}</option>
          <option value="1">{translations.MYTD_endUserLbl}</option>
          <option value="2">{translations.MYTD_familyMbrLbl}</option>
          <option value="3">{translations.MYTD_caregiverLbl}</option>
          <option value="4">{translations.MYTD_proLbl}</option>
          <option value="5">{translations.Dist_Org_User}</option>
        </select>
      </div>
    </div>
  );
};
export default ProfileAccountType;
