export const messagebankMockData = {
  name: "messagebank",
  title: "Message Bank",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "My Messages", path: "messages" },
  ],
  links: {
    application: [
      {
        title: "Upload Messages",
        url: "messages",
      },
    ],
    training: [
      {
        title: "Message Bank Recorded Webinar",
        url: "https://goto.webcasts.com/starthere.jsp?ei=1135343",
      },
      {
        title: "Message Bank Support",
        url: "https://www.tobiidynavox.com/support-training/message-banking/",
      },
    ],
    resources: [
      {
        title: "Message Bank Overview",
        url: "https://www.tobiidynavox.com/software/web-applications/message-banking/",
      },
    ],
    documentation: [
      {
        title: "Message Bank Quick Start Guide",
        url: "https://download.mytobiidynavox.com/Message%20Banking/message-bank-getting-started_en.pdf",
      },
      {
        title: "Message Bank FAQs",
        url: "https://download.mytobiidynavox.com/Message%20Banking/message-bank-faq.pdf",
      },
    ],
  },
};
