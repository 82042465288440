import React from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { postAddMyProduct } from "@services/product-service";
import Translation from "@common/Translation";
import { RootState } from "store";

interface AddToMyStuffButtonProps {
  product: string;
}

const AddToMyStuffButton: React.FC<AddToMyStuffButtonProps> = ({ product }) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  const handleAddToMyStuff = async () => {
    try {
      await postAddMyProduct(product);
      toast.success(translations.Added);
    } catch (error) {
      toast.error(translations.An_error_occurred_while_processing_your_request);
      console.error("Error adding product:", error);
    }
  };

  if (!isAuthenticated) return null;

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        handleAddToMyStuff();
      }}
      data-product={product}
      className="btn btn-lg btn-default non-breadcrumb"
    >
      <Translation translate="MYTD_addToMyStuffBtnTxt" />
    </a>
  );
};

export default AddToMyStuffButton;
