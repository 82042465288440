import axios from "axios";
import { redirectToLogin } from "@services";

const baseService = axios.create({
  baseURL: `/api/`,
  timeout: 1200000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "*/*",
    "Accept-Language": navigator.language,
  },
});

baseService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  },
);

export const fetchData = async (endpoint) => {
  try {
    const response = await baseService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (endpoint, payload) => {
  try {
    const isFormData = payload instanceof FormData;
    const headers = {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    };

    const response = await baseService.post(endpoint, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const deleteData = async (endpoint, payload) => {
  try {
    const config = payload ? { data: payload } : {};
    const response = await baseService.delete(endpoint, config);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const putData = async (endpoint, payload) => {
  try {
    const response = await baseService.put(endpoint, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const getLink = (endpoint) => `/api${endpoint}`;

export default baseService;
