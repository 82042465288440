import React from "react";
import Translation from "../../../common/Translation";

const OrderHeader = () => {
  return (
    <div className="panel panel-default panel-title-row">
      <div className="panel-heading">
        <div className="panel-title">
          <div className="row">
            <div className="col-xs-5 angle-buffer">
              <Translation translate="MYTD_orderNumLbl" />
            </div>
            <div className="col-xs-3">
              <Translation translate="MYTD_orderDateLbl" />
            </div>
            <div className="col-xs-4 text-right">
              <Translation translate="MYTD_orderTotalLbl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderHeader;
