import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Translation from "@common/Translation";
import { deleteBookOrStudentById } from "@services";

const DeleteModal = ({
  showModal,
  setShowModal,
  name,
  id,
  translations,
  fetchFn,
  type,
}) => {
  const closeModal = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      await deleteBookOrStudentById(id, type);
      await fetchFn();
      closeModal();
      toast.success(translations.Successfully_Deleted);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(translations.Unsuccessfully_deleted);
    }
  };
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
      data-testid="delete-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>{name}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_confirmDeleteLbl" /> {name}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button onClick={handleDelete} className="btn btn-lg btn-primary">
            <Translation translate="MYTD_okLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
