import React from "react";
import Translation from "../Translation";

const DataSpinner: React.FC = () => {
  return (
    <div className="text-center margin-top-lg">
      <i
        className="fa fa-cog fa-spin fa-2x fa-fw"
        style={{ color: "#39a6da" }}
      />
      <div className="text-center margin-top-sm">
        <Translation translate="MYTD_Loading" textonly={true} />
      </div>
    </div>
  );
};

export default DataSpinner;
