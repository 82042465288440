import { postData } from "./base-service";

const PRODUCT_API_ENDPOINTS = {
  addProduct: (product) => `/v1/products/${product}`,
};

const postAddMyProduct = (payload) => {
  return postData(PRODUCT_API_ENDPOINTS.addProduct(payload), {});
};

export { postAddMyProduct };
