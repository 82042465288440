import React from "react";

interface CheckboxProps {
  selected: boolean;
  name: string;
  label: string;
  onSelectionChange: (name: string, isSelected: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  selected,
  name,
  label,
  onSelectionChange,
}) => (
  <span className="checkbox checkbox-link checkbox-inline custom">
    <input
      checked={selected}
      name={name}
      id={name}
      type="checkbox"
      onChange={() => onSelectionChange(name, !selected)}
      data-testid="checkbox"
    />
    <label htmlFor={name}>{label}</label>
  </span>
);

export default Checkbox;
