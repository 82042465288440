import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLicensePremium, getUserLicenseKeys } from "@services";
import LicenseCell from "./LicenseCell";
import PremiumLicenseCell from "./PremiumLicenseCell";
const Licenses = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [licenseKeys, setLicenseKeys] = useState([]);
  const [premiumLicense, setPremiumLicense] = useState([]);

  useEffect(() => {
    const fetchPremiumLicense = async () => {
      const premiumData = await getLicensePremium(languageCode);
      setPremiumLicense(premiumData);
    };
    const fetchUserLicenseKeys = async () => {
      const data = await getUserLicenseKeys(languageCode);
      setLicenseKeys(data);
    };

    fetchUserLicenseKeys();
    fetchPremiumLicense();
  }, [languageCode]);

  return (
    <div className="container">
      <div className="fade-block fade-in">
        <h3>
          <span>{translations.MYTD_KeysLbl}</span>
        </h3>
        <div className="row">
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{translations.Product}</th>
                    <th>{translations.License_Key}</th>
                  </tr>
                </thead>
                <tbody>
                  {licenseKeys.map((licenseItem, licenseIndex) => {
                    return (
                      <LicenseCell
                        item={licenseItem}
                        translations={translations}
                        key={licenseIndex}
                      ></LicenseCell>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <h3>
          <span>{translations.MYTD_PermsLbl}</span>
        </h3>
        <div className="row">
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <span>{translations.Product}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {premiumLicense.map((premItem, premIndex) => {
                    return (
                      <PremiumLicenseCell
                        premItem={premItem}
                        key={premIndex}
                      ></PremiumLicenseCell>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Licenses;
