import React from "react";
import { Link } from "react-router-dom";
import RawHTML from "@common/RawHTML/Index";

const Professionals = ({ professionals, translations }) => (
  <div className="container text-center">
    <div className="row">
      <div className="col-sm-8 col-sm-push-2">
        <h2>{professionals.title}</h2>
        <RawHTML htmlContent={professionals.description} />
        <Link to="morestuff/professionals" className="btn btn-lg btn-primary">
          {translations.Verify_Membership}
        </Link>
      </div>
    </div>
  </div>
);

export default Professionals;
