import { messagebankMockData } from "../mockData/mockData";
import { deleteData, fetchData, postData, putData } from "./base-service";

const MY_STUFF_API_ENDPOINTS = {
  activeNotifications: (langCode) => `/v1/notifications?uic=${langCode}`,
  previousNotifications: (langCode) =>
    `/v1/notifications/history?uic=${langCode}`,
  latestPageset: (langCode, product) =>
    `/v1/pagesets/${product}/latest?uic=${langCode}`,
  pagesets: (langCode, product) => `/v1/pagesets/${product}?uic=${langCode}`,
  downloadPageset: (id) => `/api/v1/pageset/download/${id}`,
  downloadCompassPageset: (id) => `/api/v1/pageset/download/compass/${id}`,
  deletePageset: (id, product) => `/v1/pagesets/${product}/${id}`,
  pagesetById: (langCode, id, product) =>
    `/v1/pagesets/${product}/${id}?uic=${langCode}`,
  latestBackup: (langCode, product) =>
    `/v1/backup/${product}/latest?uic=${langCode}`,
  backups: (langCode, product) => `/v1/backup/${product}?uic=${langCode}`,
  downloadBackup: (id, product) => `/api/v1/backup/${product}/download/${id}`,
  deleteBackup: (id, product) => `/v1/backup/${product}/${id}`,
  backupById: (langCode, id, product) =>
    `/v1/backup/${product}/${id}?uic=${langCode}`,
  documentationLinks: (langCode, product) =>
    `/v1/products/my-stuff/links/${product}?uic=${langCode}`,
  acceptNotification: (langCode, id) =>
    `/v1/notifications/${id}/accept?uic=${langCode}`,
  declineNotification: (langCode, id) =>
    `/v1/notifications/${id}/decline?uic=${langCode}`,
  tabs: (langCode, product) => `/v1/products/${product}?uic=${langCode}`,
  uploadToken: (product, type) => `/v1/${type}/${product}/get-upload-token`,
  uploadForm: (product, type) => `/v1/${type}/${product}/form`,
  shareCopy: () => `/v1/pagesets/share`,
  sync: () => `/v1/pagesets/sync`,
  pagesetCategories: () => `/v1/pageset-central/categories`,
  pagesetLanguages: () => `/v1/pageset-central/languages`,
  updatePagesetDetails: (product, type) => `/v1/${type}/${product}`,
  addPagesetToMyStuff: (product, pagesetId) =>
    `/v1/pageset-central/${product}/${pagesetId}`,
  checkEmail: (email) => `/v1/user/status?email=${email}`,
  devices: (langCode, product) => `/v1/devices/${product}?uic=${langCode}`,
  detachDevice: (product, id, allowDetachCompanions) =>
    `/v1/devices/${product}/${id}?allowDetachCompanions=${allowDetachCompanions}`,
  deactivateDevice: (serialNumber, licenseKey) =>
    `/v1/devices/license/${serialNumber}?licenseKey=${licenseKey}`,
  books: (langCode) => `/v1/backup/all/books?uic=${langCode}`,
  students: (langCode) => `/v1/backup/all/students?uic=${langCode}`,
  deleteBookOrStudent: (id, type) => `/v1/backup/all/${type}/${id}`,
  licenseKey: (product) => `/v1/licenses/${product}`,
};

export const getMyStuffData = (product) => {
  switch (product) {
    case "messagebank":
      return messagebankMockData;
    default:
      return {};
  }
};

export const getActiveNotifications = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.activeNotifications(langCode));

export const getPreviousNotifications = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.previousNotifications(langCode));

export const getLatestPageset = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.latestPageset(langCode, product));

export const getPagesets = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.pagesets(langCode, product));

export const downloadPagesetById = async (id) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadPageset(id);
};

export const downloadCompassPagesetById = async (id) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadCompassPageset(id);
};

export const deletePagesetById = async (id, product) =>
  deleteData(MY_STUFF_API_ENDPOINTS.deletePageset(id, product));

export const getPagesetById = async (langCode, id, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.pagesetById(langCode, id, product));

export const getLatestBackup = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.latestBackup(langCode, product));

export const getBackups = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.backups(langCode, product));

export const deleteBackupById = async (id, product) =>
  deleteData(MY_STUFF_API_ENDPOINTS.deleteBackup(id, product));

export const downloadBackupById = async (id, product) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadPageset(id, product);
};

export const getBackupById = async (langCode, id, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.backupById(langCode, id, product));

export const getDocumentationLinks = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.documentationLinks(langCode, product));

export const getTabs = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.tabs(langCode, product));

export const postAcceptNotification = async (langCode, id) =>
  postData(MY_STUFF_API_ENDPOINTS.acceptNotification(langCode, id));

export const declineNotification = async (langCode, id) =>
  deleteData(MY_STUFF_API_ENDPOINTS.declineNotification(langCode, id));

export const getUploadToken = async (product, type) =>
  fetchData(MY_STUFF_API_ENDPOINTS.uploadToken(product, type));

export const postUploadForm = async (product, payload, type) =>
  postData(MY_STUFF_API_ENDPOINTS.uploadForm(product, type), payload);

export const postShareCopy = async (payload) =>
  postData(MY_STUFF_API_ENDPOINTS.shareCopy(), payload);

export const deleteSync = async (pagesetId, sharedToUserId) =>
  deleteData(MY_STUFF_API_ENDPOINTS.sync(), {
    pagesetId,
    sharedToUserId,
  });

export const postSync = async (pagesetId, sharedToUserEmail) =>
  postData(MY_STUFF_API_ENDPOINTS.sync(), {
    pagesetId,
    sharedToUserEmail,
  });

export const getPagesetCategories = async () =>
  fetchData(MY_STUFF_API_ENDPOINTS.pagesetCategories());

export const getPagesetLanguages = async () =>
  fetchData(MY_STUFF_API_ENDPOINTS.pagesetLanguages());

export const putPagesetDetails = async (product, payload, type) =>
  putData(MY_STUFF_API_ENDPOINTS.updatePagesetDetails(product, type), payload);

export const addPagesetToMyStuff = async (product, pagesetId) =>
  putData(MY_STUFF_API_ENDPOINTS.addPagesetToMyStuff(product, pagesetId));

export const checkUserEmail = async (email) =>
  fetchData(MY_STUFF_API_ENDPOINTS.checkEmail(email));

export const getDevices = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.devices(langCode, product));

export const detachDeviceById = async (product, id, allowDetachCompanions) =>
  deleteData(
    MY_STUFF_API_ENDPOINTS.detachDevice(product, id, allowDetachCompanions),
  );
export const deactivateDeviceLicense = async (serialNumber, licenseKey) =>
  deleteData(MY_STUFF_API_ENDPOINTS.deactivateDevice(serialNumber, licenseKey));

export const getBooks = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.books(langCode));

export const getStudents = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.students(langCode));

export const deleteBookOrStudentById = async (id, type) =>
  deleteData(MY_STUFF_API_ENDPOINTS.deleteBookOrStudent(id, type));

export const generateLicenseKey = async (product) =>
  putData(MY_STUFF_API_ENDPOINTS.licenseKey(product));
