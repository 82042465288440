import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProductsOverview } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import MessageBankBanner from "./MessageBankBanner";

const MessageBank = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [messageBankData, setMessageBankData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProductsOverview(languageCode);
      setMessageBankData(data.messageBank);
    };
    fetchData();
  }, [languageCode]);

  return (
    messageBankData && (
      <>
        <MessageBankBanner />
        <div className="container-full">
          <div className="container myStuff-header">
            <h1>{messageBankData.title}</h1>
            <Breadcrumb
              items={[{ label: messageBankData.title }]}
              path="messagebank"
            />
          </div>
        </div>
        <div className="container-full">
          <div className="container" style={{ paddingBottom: 0 }}>
            <div className="tab-link-set">
              <Link to="/morestuff/messagebank" className="active">
                {messageBankData.support?.title}
              </Link>
              <Link to="/morestuff/messagebank/messages">
                {messageBankData.messageBankTabText}
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container ms-bank">
            <div className="margin-top-lg">
              <div className="row">
                <div className="col-sm-12 col-md-6 survey-info-block match">
                  <div>
                    {messageBankData.support?.description}
                    <br />
                    <Link
                      to={messageBankData.support?.moreInfo?.url}
                      className="learn-more"
                      target="_blank"
                      dangerouslySetInnerHTML={{
                        __html: messageBankData.support?.moreInfo?.text,
                      }}
                    />
                  </div>
                </div>

                <div
                  className="col-sm-6 col-md-3 match"
                  style={{ height: "277px" }}
                >
                  <div className="myStuff-panel">
                    <div className="myStuff-panel-top">
                      <h4>{translations.Message_Bank}</h4>
                      <br />
                      <div>
                        <div className="icon-logo-message-bank icon icon-56"></div>
                      </div>
                    </div>
                    <div className="myStuff-panel-btm clearfix track-clicks">
                      <div className="btn-link-list">
                        <Link
                          to="/mystuff/messagebank/messages"
                          className="btn-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Upload Messages
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-sm-6 col-md-3 match"
                  style={{ height: "277px" }}
                >
                  <div className="myStuff-panel">
                    <div className="myStuff-panel-top">
                      <h4>{translations.Documentation}</h4>
                      <br />
                      <div>
                        <div className="icon-resources icon icon-56"></div>
                      </div>
                    </div>
                    <div className="myStuff-panel-btm">
                      <div className="btn-link-list">
                        <Link
                          to="/Support/Documentation#MessageBank"
                          className="btn-link link-message-bank"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {messageBankData.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default MessageBank;
