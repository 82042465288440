import React from "react";
import RawHTML from "@common/RawHTML/Index";
import SystemDownloadButton from "./Common/SystemDownloadButton";
import ProductBuyButton from "./Common/BuyButton";

const TryItOuts = ({ tryItOuts, translations, product }) => (
  <>
    {tryItOuts.tryItOutApps.applications.map((app, index) => (
      <div className="container-full" key={`${app.type}-${index}`}>
        <div id="shopNow" className="container text-center">
          <div className="row">
            <div className="col-sm-8 col-sm-push-2">
              <h2>{app.type}</h2>
              <RawHTML htmlContent={app.description} />
            </div>
          </div>
          {app.apps[0].title === null ? (
            <>
              <ProductBuyButton
                product={product}
                text={translations.Start_Trial}
                trial={true}
                className="btn btn-lg btn-primary"
              />
            </>
          ) : (
            <>
              <div className="row jump-links">
                <div className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4">
                  <div className="well-product-link">
                    <div className="match_desc">
                      <div className="hidden-xs icon icon-download icon-68"></div>
                      <h3>{app.apps[0].title}</h3>
                      <RawHTML htmlContent={app.apps[0].description} />
                    </div>
                    <div className="match_cta pricing">
                      <hr />
                      {app.apps.map((download, i) => (
                        <SystemDownloadButton
                          key={i}
                          url={download.links}
                          system={download.system}
                          translations={translations}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    ))}
  </>
);

export default TryItOuts;
