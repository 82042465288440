import React from "react";
import Translation from "@common/Translation";

const ProfileAddress = ({ handleTextUpdate, profile }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="streetAddressLine1">
          {<Translation translate={"MYTD_add1Lbl"}></Translation>}
        </label>{" "}
        <span className="optional">
          {<Translation translate={"MYTD_optionalTxt"}></Translation>}
        </span>
        <input
          className="form-control"
          id="streetAddressLine1"
          name="address.streetAddressLine1"
          type="text"
          value={profile.address.streetAddressLine1 || ""}
          onChange={handleTextUpdate}
          key={"streetAddressLine1"}
        />
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="streetAddressLine2">
          {<Translation translate={"MYTD_add2Lbl"}></Translation>}
        </label>{" "}
        <span className="optional">
          {<Translation translate={"MYTD_optionalTxt"}></Translation>}
        </span>
        <input
          className="form-control"
          id="streetAddressLine2"
          name="address.streetAddressLine2"
          type="text"
          value={profile.address.streetAddressLine2 || ""}
          onChange={handleTextUpdate}
          key={"streetAddressLine2"}
        />
      </div>
    </div>
  );
};
export default ProfileAddress;
