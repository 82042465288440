import React from "react";
import Translation from "../../../common/Translation";

const CollapseComponent = ({ orderData }) => {
  return (
    <>
      <div className="orders-details">
        <div className="pull-left">
          <Translation translate="MYTD_orderNumLbl" />
          <strong>{orderData.number}</strong>
        </div>
      </div>
      <table className="table orders">
        <thead>
          <tr>
            <th>
              <Translation translate="MYTD_productLbl" />
            </th>
            <th>
              <Translation translate="MYTD_quantityLbl" />
            </th>
            <th>
              <Translation translate="MYTD_priceLbl" />
            </th>
          </tr>
        </thead>

        <tbody>
          {orderData.items?.map((item, i) => {
            return (
              <tr key={i}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
              </tr>
            );
          })}

          <tr>
            <td colSpan="2">
              <strong>
                <Translation translate="MYTD_totalLbl" />
              </strong>
            </td>
            <td>{orderData.total}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default CollapseComponent;
