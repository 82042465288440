import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  translationsReducer,
  userDetailsReducer,
  languageDataReducer,
  commonDataReducer,
} from "./reducers";

const rootReducer = combineReducers({
  translations: translationsReducer,
  languageData: languageDataReducer,
  user: userDetailsReducer,
  common: commonDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const getConfiguredStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunk],
  });

export const store = getConfiguredStore();
