import React from "react";
import { Link } from "react-router-dom";
import AddToMyStuffButton from "./AddToMyStuffButton";

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  path?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, path }) => {
  return (
    <div key="breadcrumb">
      <ol className="breadcrumb pull-left">
        <li key="home">
          <Link to="/">
            <span className="icon icon-home icon-21"></span>
          </Link>
        </li>

        {items &&
          items.map((item, index) => (
            <li key={`${item.label}_${index}_`}>
              {item.href ? (
                <Link to={item.href}>{item.label}</Link>
              ) : (
                <span
                  className="breadcrumb-current-page"
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              )}
            </li>
          ))}

        {path && <AddToMyStuffButton product={path} />}
      </ol>
    </div>
  );
};

export default Breadcrumb;
