import { fetchData, getLink } from "./base-service";

export const STORE_API_ENDPOINTS = {
  storeInnerPage: (countryCode, page, langCode) =>
    `/v1/store/purchase-options/${countryCode}/${page}?uic=${langCode}`,
  storePage: (langCode) => `/v1/store?uic=${langCode}`,
  storeBuyPage: (product, trial) =>
    trial
      ? `/v1/store/buy-now/${product}?trial=${trial}`
      : `/v1/store/buy-now/${product}`,
};

export const getStoreInnerPageData = async (countryCode, page, langCode) =>
  fetchData(STORE_API_ENDPOINTS.storeInnerPage(countryCode, page, langCode));

export const getLanguages = (products) => {
  const languageSet = new Set();

  return products
    .flatMap((product) => product.languages)
    .filter((language) => {
      if (languageSet.has(language.name)) {
        return false;
      } else {
        languageSet.add(language.name);
        return true;
      }
    })
    .map((language) => ({
      value: language.name,
      label: language.value,
      type: "option",
    }));
};

export const getStoreCategories = ({ id, title, tagline, description }) => {
  return [
    {
      id,
      title,
      tagline,
      description,
    },
  ];
};

export const getStoreOfferings = async (langCode) =>
  fetchData(STORE_API_ENDPOINTS.storePage(langCode));

export const getStoreLink = (product, trial = false) =>
  getLink(STORE_API_ENDPOINTS.storeBuyPage(product, trial));
