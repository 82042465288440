import React from "react";
import RawHTML from "@common/RawHTML/Index";
import { TrialText } from "./Common";

const Upgrade = ({ upgrades, translations, snapTrialsData }) => {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-sm-8 col-sm-push-2">
          <h2>{upgrades.title}</h2>
        </div>
      </div>

      {upgrades.possibleUpgrades.applications.map((app, index) => (
        <div
          key={index}
          className="well well-white text-left"
          style={{ padding: "0 15px" }}
        >
          <div className="row equal">
            <div className="col-12 col-md-8 equal-content equal-main">
              <p>
                <strong>{app.title}</strong>
              </p>
              <div>
                <RawHTML htmlContent={app.description} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="row">
                {app.apps.map((subApp, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <hr className="visible-xs-block visible-sm-block" />
                    <div className="col-12 equal-content">
                      <div className="pull-left text-left">
                        {subApp.availability.includes("Trial_60_Days") && (
                          <TrialText
                            trialPeriodText={translations.Start_Day_Trial}
                            trialPeriodEndedText={translations.Day_Trial_Ended}
                            trialDaysRemainingText={translations.Days_remaining}
                            trialLink={`start-trial?product=${app.title}`}
                            trialPeriod="60"
                            trialToken={snapTrialsData.find(
                              (token) => token?.type === app.title,
                            )}
                          />
                        )}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Upgrade;
