import React from "react";
import Translation from "../../common/Translation";

const Step3 = ({
  stepNumber,
  replaceArray,
  logIn,
  logInToMyTDDuringAppStartUp,
  followInstructions,
}) => {
  return (
    <div className="col-md-4">
      <div className="well well-match jump-links text-center">
        <div className="icon icon-member icon-68 data-icon"></div>
        <h3 className="trial-well-header">
          <Translation translate={stepNumber} replaceArray={replaceArray} /> -{" "}
          <Translation translate={logIn} />
        </h3>
        <div className="trial-well-body" style={{ height: "102px" }}>
          <p>
            <Translation translate={logInToMyTDDuringAppStartUp} />
          </p>
        </div>
        <div className="trial-well-footer">
          <hr />
          <p>
            <Translation translate={followInstructions} />
          </p>
        </div>
      </div>
    </div>
  );
};
export default Step3;
