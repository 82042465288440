import { useEffect } from "react";

const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    // Cleanup function to reset title when component unmounts
    return () => {
      if (document.title === title) {
        document.title = prevTitle || "myTobiiDynavox";
      } else {
        document.title = "myTobiiDynavox";
      }
    };
  }, [title]);
};

export default useTitle;
