import React from "react";
import Translation from "@common/Translation";
import { redirectToEdit, redirectToChangePassword } from "@services";
const ProfileDetails = ({ profile }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-4">
          <p className="control-label">
            {profile.firstName} {profile.lastName}
          </p>
          <p className="control-label">{profile.email}</p>
          <p className="control-label">
            <Translation translate={profile.address.countryTranslationKey} />
          </p>
        </div>
        <div className="col-sm-6 col-md-4">
          <a
            className="btn btn-default"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              redirectToEdit();
            }}
          >
            <Translation translate={"MYTD_changeLbl"}></Translation>
          </a>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6 col-md-4">
          <label className="control-label">
            <Translation translate="Password" />: ********
          </label>
        </div>
        <div className="col-sm-6 col-md-4">
          <a
            className="btn btn-default"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              redirectToChangePassword();
            }}
          >
            <Translation translate={"MYTD_changeLbl"}></Translation>
          </a>
        </div>
      </div>
    </>
  );
};
export default ProfileDetails;
