import React, { useEffect, useState } from "react";
import Translation from "../../common/Translation";
import { getStartSnapTrialData } from "../../../services";
import { redirectToLogin } from "../../../services";

const Step1 = ({
  isAuthenticated,
  snapTrialsData,
  product,
  trialIsActive,
  trialHasStarted,
  daysRemaining,
  trialHasExpired,
  trialHasEnded,
  startTrial,
  trialHasNotStarted,
  stepNumber,
  replaceArray,
  activate,
}) => {
  const [isTrialActive, setIsTrialActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const trial = snapTrialsData?.find((trial) => trial?.type === product);
    setIsTrialActive(trial?.active || false);
    setTimeLeft(trial?.timeLeft || 0);
  }, [snapTrialsData, product]);

  const handleStartSnapTrial = async () => {
    if (!isAuthenticated) {
      redirectToLogin();
      return;
    }

    if (isTrialActive) return;

    try {
      await getStartSnapTrialData(product);
      setIsTrialActive(true);
      setTimeLeft(60);
    } catch (error) {
      console.error("Failed to start trial", error);
    }
  };

  const renderTrialStatus = () => {
    if (isTrialActive && timeLeft > 0) {
      return (
        <>
          <div className="trial-well-body" style={{ height: "102px" }}>
            <div className="btn btn-lg btn-placeholder">
              <Translation translate={trialIsActive} />
            </div>
          </div>
          <div className="trial-well-footer">
            <hr />
            <p>
              <Translation translate={trialHasStarted} />
            </p>
            <p>
              <Translation translate={daysRemaining} /> - {timeLeft}
            </p>
          </div>
        </>
      );
    }

    if (isTrialActive && timeLeft === 0) {
      return (
        <>
          <div className="trial-well-body" style={{ height: "102px" }}>
            <div className="btn btn-lg btn-placeholder">
              <Translation translate={trialHasExpired} />
            </div>
          </div>
          <div className="trial-well-footer">
            <hr />
            <p style={{ height: "42.84px" }}>
              <Translation translate={trialHasEnded} />
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="trial-well-body" style={{ height: "102px" }}>
          <a
            href="#"
            className="btn btn-primary btn-lg"
            onClick={handleStartSnapTrial}
          >
            <Translation translate={startTrial} />
          </a>
        </div>
        <div className="trial-well-footer">
          <hr />
          <p style={{ height: "42.84px" }}>
            <Translation translate={trialHasNotStarted} />
          </p>
        </div>
      </>
    );
  };

  return (
    <div className="col-md-4">
      <div className="well well-match jump-links text-center">
        <div className="icon icon-active-account-status icon-68 data-icon"></div>
        <h3 className="trial-well-header">
          <Translation translate={stepNumber} replaceArray={replaceArray} /> -{" "}
          <Translation translate={activate} />
        </h3>
        {renderTrialStatus()}
      </div>
    </div>
  );
};

export default Step1;
