import React from "react";
import Translation from "@common/Translation";

const MessagesList = ({ messages }) => {
  if (!messages || messages.length === 0) {
    return <div>&nbsp;</div>;
  }

  return (
    <div className="usage-block container-fluid">
      <h2>
        <Translation translate="Most_Used_Messages" textonly="true" />
      </h2>

      <table className="table table-hover mtd-table fade-block fade-in message-list-wrapper">
        <thead>
          <tr className="row">
            <th className="col-xs-2 col-sm-1">
              <Translation translate="Rank" textonly="true" />
            </th>
            <th className="col-xs-7 col-sm-9">
              <Translation translate="Message" textonly="true" />
            </th>
            <th className="col-xs-3 col-sm-2 text-right">
              <Translation translate="Count" textonly="true" />
            </th>
          </tr>
        </thead>
        <tbody>
          {messages.map((item, index) => (
            <tr className="row" key={item.id || index}>
              <td className="col-xs-2 col-sm-1 lighter-text">{index + 1}</td>
              <td className="col-xs-7 col-sm-9">{item.message}</td>
              <td className="col-xs-3 col-sm-2 lighter-text text-right">
                {item.count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MessagesList;
