import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  formatBytes,
  handleDownload,
  handleAdd,
  getImageSrc,
  determineImagePath,
} from "@helpers/utilities";

const PagesetItem = ({ ps, localizationResources }) => {
  const [imageSrc, setImageSrc] = useState("");
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const { languageCode } = useSelector((state) => state.languageData);
  formatBytes;

  useEffect(() => {
    const imagePath = determineImagePath(ps.imageUrl, ps.software);
    setImageSrc(imagePath);
  }, [ps.imageUrl, ps.software]);

  return (
    <div className="pageset">
      <div className="thumbnail-actions text-center">
        <Link to={`/psc/${ps.software}/${ps.pagesetId}`}>
          <img
            src={getImageSrc(ps.software, imageSrc)}
            alt={ps.software}
            className="img-thumbnail"
            style={{ minHeight: "150px" }}
          />
        </Link>
      </div>
      <div className="details" style={{ minHeight: "610px" }}>
        <h4 className="truncate lines-2">
          <Link to={`/psc/${ps.software}/${ps.pagesetId}`}>{ps.title}</Link>
        </h4>
        <h5 dangerouslySetInnerHTML={{ __html: ps.description }} />
        <div className="filters">
          <div className="clearfix">
            <div className="pull-left">
              {ps.author && <h6>by {ps.author}</h6>}
            </div>
            <div className="pull-right">
              <h6>
                {new Date(ps.dateCreatedUtc).toLocaleDateString(languageCode)}
              </h6>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="clearfix">
            <div className="pull-left">
              <h6>{ps.compatibleWith.join(", ")}</h6>
            </div>
            <div className="pull-right">
              <h6>{ps.language}</h6>
            </div>
          </div>
        </div>
        {ps.type && (
          <div className="filters">
            <div className="clearfix">
              <div className="pull-left">
                <h6>{ps.type}</h6>
              </div>
            </div>
          </div>
        )}
        {ps.categories && (
          <div className="filters">
            <h6>Categories</h6>
            <br />
            <div className="clearfix">
              {ps.categories.map((category) => (
                <div key={category} className="">
                  {category}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="details2 clearfix">
        <div
          className="stats pull-left"
          data-toggle="tooltip"
          data-placement="bottom"
          title={localizationResources.Downloads}
        >
          <span className="icon icon-14 icon-downloads" /> {ps.downloads}
        </div>
        <div className="stats pull-left">{formatBytes(ps.size)}</div>
        <div className="pull-right">
          <a
            href=""
            className="stats"
            onClick={() => handleAdd(isAuthenticated)}
          >
            {localizationResources.MYTD_addToMyStuffBtnTxt}
          </a>
          <a
            href="#"
            className="ng-binding"
            onClick={(e) => {
              e.preventDefault();
              handleDownload(ps.pagesetId, ps.software);
            }}
          >
            {localizationResources.Download}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PagesetItem;
