import React from "react";
import { SystemBuyButton, SystemDownloadButton } from "./Common";

const OneTimePurchases = ({ oneTimePurchases, translations, pageKey }) => {
  return (
    <>
      {oneTimePurchases.applications.map((app, index) => (
        <div
          className={`${
            pageKey === "EarlyIntervention" ? "container-full" : "container"
          } text-center`}
          key={`${app.type}-${index}`}
        >
          <div className="row">
            <div className="col-sm-8 col-sm-push-2">
              <h2>{app.type}</h2>
              <p>{app.description}</p>
              <p className="note">{oneTimePurchases.headerNote}</p>
            </div>
          </div>
          {pageKey === "EarlyIntervention" ? (
            <div className="row jump-links">
              {oneTimePurchases?.applications[0]?.apps.map((app) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3" key={app.title}>
                    <div
                      className="well-product-link"
                      style={{ minHeight: "490px", minWidth: "260px" }}
                    >
                      <div className="match_desc">
                        <div className="hidden-xs icon icon-store icon-68"></div>
                        <h3>{app.title}</h3>
                        <p>{app.description}</p>
                        <p className="note">
                          {
                            translations.Early_Intervention_Download_Pathways_from_iTunes_No_Cost
                          }
                        </p>
                      </div>
                      <div className="match_cta pricing">
                        <hr />
                        <h3>{app.price}</h3>
                        <h4>{app.priceNoteHeader}</h4>
                        <SystemBuyButton
                          product={pageKey}
                          system={app.system}
                          translations={translations}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row jump-links">
              <div className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4">
                <div className="well-product-link">
                  <div className="match_desc">
                    <div className="hidden-xs icon icon-store icon-68"></div>
                    <h3>{app.apps[0].title}</h3>
                    <p>{app.apps[0].description}</p>
                  </div>
                  <div className="match_cta pricing">
                    <hr />
                    {app.apps.some((d) => d.price !== null) && (
                      <>
                        <h3>
                          {app.apps.find((app) => app.price !== null)?.price}
                        </h3>
                        <h4>{translations.One_Time_Purchase}</h4>
                        {app.apps
                          .filter((d) => d.price !== null)
                          .map((download, index) => {
                            return (
                              <SystemBuyButton
                                key={index}
                                product={pageKey}
                                system={download.system}
                                translations={translations}
                              />
                            );
                          })}
                      </>
                    )}
                    <hr />
                    {app.apps.some((d) => d.price === null) && (
                      <>
                        {app.apps
                          .filter((d) => d.price === null)
                          .map((download, index) => (
                            <div
                              key={`${app.type}-${download.system}-${index}`}
                            >
                              <SystemDownloadButton
                                url={download.links}
                                system={download.system}
                                translations={translations}
                              />
                              <div className="footnote">
                                {download.priceNoteFooter}
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {oneTimePurchases.footerNote && (
            <div className="row">
              <div className="footnote">
                <small>{oneTimePurchases.footerNote}</small>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default OneTimePurchases;
