import React from "react";
import { useSelector } from "react-redux";
import LinkSpinner from "./LinkSpinner";
import Translation from "@common/Translation";
import { postAcceptNotification, declineNotification } from "@services";

const ActionLinks = ({ action, id, onActionComplete }) => {
  const { languageCode } = useSelector((state) => state.languageData);

  const acceptText = <Translation translate="MYTD_acceptLbl" />;
  const declineText = <Translation translate="MYTD_declineLbl" />;

  const handleActionClick = async (action) => {
    if (action === "accept") {
      await postAcceptNotification(languageCode, id);
      if (typeof onActionComplete === "function") {
        onActionComplete();
      }
    } else if (action === "decline") {
      await declineNotification(languageCode, id);
      if (typeof onActionComplete === "function") {
        onActionComplete();
      }
    }
  };

  if (action === "share_copy" || action === "share_sync") {
    return (
      <>
        <LinkSpinner>
          <a href="#" onClick={() => handleActionClick("accept")}>
            {acceptText}
          </a>
        </LinkSpinner>
        <LinkSpinner>
          <a href="#" onClick={() => handleActionClick("decline")}>
            {declineText}
          </a>
        </LinkSpinner>
      </>
    );
  } else if (action === "stop_sync" || action === "left_sync") {
    return (
      <LinkSpinner>
        <a href="#" onClick={() => handleActionClick("accept")}>
          {acceptText}
        </a>
      </LinkSpinner>
    );
  } else if (action.includes("messagebundle")) {
    return (
      <LinkSpinner>
        <a href="#" onClick={() => handleActionClick("accept")}>
          <Translation translate="OK" />
        </a>
      </LinkSpinner>
    );
  }

  return null;
};

export default ActionLinks;
