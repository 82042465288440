import React from "react";
import Translation from "../../../common/Translation";

const SubButtons = ({ subItem, setShowModal }) => {
  return (
    <p>
      {subItem?.fastSpring?.customerUrl && (
        <a
          href={subItem?.fastSpring?.customerUrl}
          target="_blank"
          rel="noreferrer"
          className="btn btn-lg btn-primary"
        >
          <Translation translate="MYTD_modifyPaymentLbl" />
        </a>
      )}

      {subItem.isAutoRenew && (
        <a
          href="#"
          className="btn btn-lg btn-primary margin-sm-left"
          onClick={() => setShowModal(true)}
        >
          <Translation translate="MYTD_cancelBtnTxt" />
        </a>
      )}
    </p>
  );
};

export default SubButtons;
