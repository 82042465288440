import React from "react";
import Translation from "@common/Translation";

const OrgUserComp = ({
  bmoDistrictCode,
  handleBmoDistrictCodeChange,
  codeChecking,
  codeValid,
  districtCodeData,
  toggleModal,
  codeDeleting,
  handleDistCodeCheck,
}) => {
  return (
    <React.Fragment>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="bmoDistrictCode">
          {<Translation translate={"Dist_Org_Code"}></Translation>}
        </label>
        <div className="input-group">
          <input
            className="form-control"
            id="bmoDistrictCode"
            name="bmoDistrictCode"
            type="text"
            value={bmoDistrictCode}
            onChange={handleBmoDistrictCodeChange}
            disabled={codeChecking || codeValid}
            key={"bmoDistrictCode"}
          />
          <span className="input-group-btn bmo-dist-check">
            {districtCodeData ? (
              <button
                className="btn btn-default"
                type="button"
                onClick={toggleModal}
                disabled={codeChecking}
              >
                {!codeDeleting ? (
                  <Translation translate="Delete_Code" />
                ) : (
                  <React.Fragment>
                    Deleting&nbsp;
                    <i
                      className="fa fa-circle-o-notch fa-spin"
                      aria-hidden="true"
                    ></i>
                  </React.Fragment>
                )}
              </button>
            ) : (
              <button
                className="btn btn-default"
                type="button"
                onClick={(e) => handleDistCodeCheck(e, bmoDistrictCode)}
                disabled={codeChecking}
              >
                {!codeChecking ? (
                  <Translation translate="Check_code" />
                ) : (
                  <React.Fragment>
                    <Translation translate="Checking" />
                    &nbsp;
                    <i
                      className="fa fa-circle-o-notch fa-spin"
                      aria-hidden="true"
                    ></i>
                  </React.Fragment>
                )}
              </button>
            )}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default OrgUserComp;
