import React from "react";

import Tile from "./Tile";
import { productConfig } from "../../../utils/productConfig";

const renderTiles = (
  tilegroup,
  notificationCount,
  avatar,
  showAvatar,
  showNotifications,
  isMoreStuff,
  config,
) => {
  if (!tilegroup) return null;

  return tilegroup.map((p, i) => {
    const product = config[p];
    if (!product) return null;

    switch (p) {
      case "myAccount":
        return (
          <Tile
            key={i}
            product={product}
            isMoreStuff={isMoreStuff}
            avatar={avatar}
            showAvatar={showAvatar}
          />
        );
      case "Notifications":
        return (
          <Tile
            key={i}
            product={product}
            isMoreStuff={isMoreStuff}
            notifications={notificationCount}
            showNotifications={showNotifications}
          />
        );
      default:
        return <Tile key={i} product={product} isMoreStuff={isMoreStuff} />;
    }
  });
};

const TileSet = ({
  tilegroup,
  notifications,
  avatar,
  showAvatar,
  showNotifications,
  isMoreStuff,
}) => {
  return (
    <div className="tiles row">
      {renderTiles(
        tilegroup,
        notifications,
        avatar,
        showAvatar,
        showNotifications,
        isMoreStuff,
        productConfig,
      )}
    </div>
  );
};

export default TileSet;
