import React from "react";
import CollapseComponent from "./CollapseComponent";
import Moment from "react-moment";
import { momentLocaleFix } from "../../../../helpers/utilities";
import PrintBtn from "./PrintBtn";

const OrderDetails = ({ orderData }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="panel panel-default printable-area" id="noBorderPanel">
      <div className="panel panel-default">
        <div className="panel-heading panel-heading-white">
          <div className="panel-title">
            <div className="row">
              <div className="col-xs-5">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  data-target={"#order_" + orderData.number}
                  aria-expanded="false"
                  aria-controls={"order_" + orderData.number}
                >
                  {orderData.number}
                </a>
              </div>
              <div className="col-xs-3">
                <Moment
                  locale={momentLocaleFix}
                  format="L"
                  date={orderData.date}
                />
              </div>
              <div className="col-xs-4 text-right">{orderData.total}</div>
            </div>
          </div>
        </div>
        <div
          id={"order_" + orderData.number}
          className="panel-collapse collapse"
        >
          <div id="thisInvoiceNumber" className="panel-body panel-body-gray">
            <CollapseComponent orderData={orderData} />
          </div>
          <PrintBtn handlePrint={handlePrint}></PrintBtn>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
