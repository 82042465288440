import React from "react";
import Translation from "../../../common/Translation";
import Moment from "react-moment";

const ContractDates = ({ subItem, momentLocaleFix }) => (
  <div className="well well-white clearfix">
    <div className="pull-left">
      <Translation translate="MYTD_startedOnLbl" />
      <strong>
        <Moment locale={momentLocaleFix} format="LL">
          {subItem.contractStartDate}
        </Moment>
      </strong>
    </div>
    <div className="pull-right">
      {subItem.isAutoRenew ? (
        <Translation translate="MYTD_renewsOnLbl" />
      ) : (
        <Translation translate="MYTD_endsOnLbl" />
      )}
      <strong>
        <Moment locale={momentLocaleFix} format="LL">
          {subItem.contractEndDate}
        </Moment>
      </strong>
    </div>
  </div>
);
export default ContractDates;
