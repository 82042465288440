import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import toast from "react-hot-toast";
import {
  getImageSrc,
  determineImagePath,
  formatBytes,
  momentLocaleFix,
} from "@helpers/utilities";
import { getPagesetById, deleteSync, postSync } from "@services";
import Translation from "@common/Translation";
import RemoveSyncModal from "@common/PagesetBox/RemoveSyncModal";
import PagesetNote from "./PagesetNote";
import PagesetEditForm from "./PagesetEditForm";
import Share from "./Share";
import SyncForm from "./PagesetSyncForm";

const Details = () => {
  const { id, product } = useParams();
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);

  const [pageset, setPageset] = useState({});
  const [editableFields, setEditableFields] = useState({
    title: "",
    description: "",
    isPublic: false,
    author: null,
    languageCode: null,
    categories: [],
  });
  const [showEdit, setShowEdit] = useState(false);
  const [showRemoveSyncModal, setShowRemoveSyncModal] = useState(false);

  const imagePath = determineImagePath(pageset.imageUrl, pageset.software);
  const imageSource = getImageSrc(pageset.software, imagePath);
  const shouldPSC = () =>
    product.toLowerCase() !== "snapscene" &&
    product.toLowerCase() !== "symbolmate";

  const fetchData = async () => {
    const data = await getPagesetById(languageCode, id, product);
    setPageset(data);
    setEditableFields({
      title: data.title || "",
      description: data.description || "",
      isPublic: data.isPublic || false,
      author: data.author || null,
      languageCode: data.languageCode || null,
      categories: data.categories || [],
    });
  };

  useEffect(() => {
    fetchData();
  }, [languageCode, id, product]);

  const handleEditClick = (e) => {
    e.preventDefault();
    setShowEdit(true);
  };

  const renderEditLink = () => {
    return (
      <a href="#" onClick={handleEditClick}>
        {shouldPSC() ? (
          <Translation translate="Edit_Share_PSC" textonly="true" />
        ) : (
          <Translation translate="Edit" textonly="true" />
        )}
      </a>
    );
  };

  const isSharedForSync = (data) => data.tags?.includes("shared_sync") ?? false;
  const shareEmailList = []; // TODO: api call to get the list

  const handleRemoveSync = async (id) => {
    const userId = id || pageset.userId;
    try {
      await deleteSync(pageset.id, userId);
      await fetchData();
      setShowRemoveSyncModal(false);
      toast.success(translations.Save_successful);
    } catch (error) {
      console.error("Error removing sync:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  const handleAddSync = async (email) => {
    try {
      await postSync(pageset.id, email);
      await fetchData();
      toast.success(translations.Save_successful);
    } catch (error) {
      console.error("Error removing sync:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <div className="psc-details">
      <div className="row psc-details-info">
        <div className="col-md-5 col-md-push-7 details">
          <h2>
            <Translation translate="MYTD_PagesetLbl" textonly="true" />{" "}
            <Translation translate="MYTD_detailsLbl" textonly="true" />
            {editableFields.isPublic && (
              <span
                className="icon icon-pageset-central icon-34 pull-right data-icon"
                title={translations.MYTD_sharedtoPCMsg}
              ></span>
            )}
            {isSharedForSync(pageset) && (
              <span
                className="icon icon-pageset-share-sync icon-34 pull-right data-icon"
                title={translations.MYTD_syncedWithOthersMsg}
              ></span>
            )}
            {isSharedForSync(pageset) && pageset.isOwner && (
              <span className="icon icon-34 pull-right data-icon"> </span>
            )}
            {pageset.isOwner && (
              <span
                className="icon icon-pageset-sync icon-34 pull-right data-icon"
                title={translations.MYTD_syncablepagesetLbl}
              ></span>
            )}
          </h2>

          <div className="table-responsive">
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>
                    <Translation translate="Date" textonly="true" />
                  </td>
                  <td>
                    {pageset.dateCreatedUtc ? (
                      <Moment locale={momentLocaleFix} format="LL">
                        {pageset.dateCreatedUtc}
                      </Moment>
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translation translate="Size" textonly="true" />
                  </td>
                  <td>
                    {pageset.sizeInBytes ? (
                      formatBytes(pageset.sizeInBytes)
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translation translate="Software" textonly="true" />
                  </td>
                  <td>
                    {pageset.software ? (
                      pageset.software === "SnapCoreFirst" ? (
                        "TD Snap"
                      ) : (
                        pageset.software
                      )
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>

                {showEdit ? (
                  <tr>
                    <td colSpan="2" className="form-cell">
                      <hr />
                      <PagesetEditForm
                        shouldPSC={shouldPSC}
                        editableFields={editableFields}
                        setEditableFields={setEditableFields}
                        translations={translations}
                        handleCancelClick={() => setShowEdit(false)}
                        product={product}
                        id={id}
                        setPageset={setPageset}
                        type="pagesets"
                      />
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td colSpan="2">
                        <hr />
                        {!["Tobii DynaVox Pagesets", "sync"].some((tag) =>
                          pageset.tags?.includes(tag),
                        ) && (
                          <div style={{ textAlign: "right" }}>
                            {renderEditLink()}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Translation translate="Title" textonly="true" />
                      </td>
                      <td>
                        {pageset.title ? (
                          pageset.title
                        ) : (
                          <Translation
                            translate="Not_Available"
                            textonly="true"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Translation translate="Description" textonly="true" />
                      </td>
                      <td>
                        {pageset.description ? (
                          pageset.description
                        ) : (
                          <i>
                            <Translation
                              translate="Not_Available"
                              textonly="true"
                            />
                          </i>
                        )}
                      </td>
                    </tr>
                    {shouldPSC() && (
                      <tr>
                        <td>PSC</td>
                        <td>
                          {editableFields.isPublic ? (
                            <Translation
                              translate="MYTD_sharedtoPCMsg"
                              textonly="true"
                            />
                          ) : (
                            <Translation
                              translate="Not_Shared_PSC"
                              textonly="true"
                            />
                          )}
                        </td>
                      </tr>
                    )}
                    {isSharedForSync(pageset) ? (
                      <SyncForm
                        itemOwner={pageset.owner}
                        setShowRemoveSyncModal={setShowRemoveSyncModal}
                        isOwner={pageset.isOwner}
                        handleRemoveSync={handleRemoveSync}
                        handleAddSync={handleAddSync}
                        translations={translations}
                        shareEmailList={shareEmailList}
                      />
                    ) : (
                      <Share
                        translations={translations}
                        type="Pageset"
                        product={product}
                        id={id}
                      />
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-6 col-md-pull-5 hidden-xs hidden-sm">
          <div className="well text-center">
            <img src={imageSource} alt="" />
          </div>
          <p className="description">
            <PagesetNote
              product={product}
              itemtype="pagesets"
              isPublic={editableFields.isPublic}
              isPremium={pageset.tags?.includes("Tobii DynaVox Pagesets")}
              isSynced={pageset.tags?.includes("shared_sync")}
              isSyncable={pageset.isOwner}
            />
          </p>
        </div>
      </div>
      <RemoveSyncModal
        showModal={showRemoveSyncModal}
        setShowModal={setShowRemoveSyncModal}
        title={pageset.title}
        handleRemoveSync={handleRemoveSync}
      />
    </div>
  );
};

export default Details;
