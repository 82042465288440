import { useLocation } from "react-router-dom";
import useTitle from "@common/UseTitle/Index";
import { useSelector } from "react-redux";

const routeTitles = {
  "/store": "Store",
  "/pageset-central": "Pageset_Central",
  "/support/documentation": "Documentation",
  "/support/downloads": "Downloads",
  "/support/professional-resources": "Professional_Resources",
  "/support/accessible-literacy-learning": "Accessible_Literacy_Learning_ALL",
  "/support/beam": "Beam",
  "/support/boardmaker-student-center-app": "Boardmaker_Student_Center",
  "/support/classic-tobii-gaze-interaction-software":
    "Classic_Tobii_Gaze_Interaction_Software",
  "/support/communicator-5": "Communicator_5",
  "/support/gaze-point": "Gaze_Point",
  "/support/gaze-viewer": "Gaze_Viewer",
  "/support/mirror-for-android": "Mirror_for_Android",
  "/support/pathways": "Pathways",
  "/support/pceye": "PCEye5_Software_Installer",
  "/support/td-snap": "SnapCoreFirst",
  "/support/snap-scene": "Snap_Scene",
};

const splatRoutes = {
  "/psc/": "Pageset_Details",
};

function TitleSetter() {
  const translationData = useSelector((state) => state.translations);
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);

  let title = isAuthenticated
    ? translationData.translations["My_Stuff"]
    : "myTobiiDynavox";

  if (routeTitles[location.pathname]) {
    title =
      translationData.translations[routeTitles[location.pathname]] ||
      routeTitles[location.pathname];
  } else {
    // Check if the path matches any of the splat routes
    for (const [path, titleText] of Object.entries(splatRoutes)) {
      if (location.pathname.startsWith(path)) {
        title = translationData.translations[titleText] || titleText;
        break;
      }
    }
  }

  useTitle(title);

  return null; // This component doesn't render anything
}

export default TitleSetter;
