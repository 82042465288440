import React from "react";
import Translation from "@common/Translation";
import Product from "./Product";
import { productConfig } from "../../../../utils/productConfig";

const renderProducts = (productgroup) => {
  if (!productgroup) return null;

  return productgroup.map((p, i) => (
    <Product key={i} product={productConfig[p]} />
  ));
};

const Products = () => {
  const productGroup = ["Communicator", "GazeViewer", "Snap"];
  return (
    <div className="products-wrap">
      <div className="container products-list row">
        <h3 className="list-header fade-block fade-in">
          <Translation translate="MYTD_tobiiProIntroMsg1" />
        </h3>
        {renderProducts(productGroup)}
      </div>
      <div className="container row post-script">
        <Translation translate="MYTD_tobiiProIntroMsg2" />
      </div>
    </div>
  );
};

export default Products;
