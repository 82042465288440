import React from "react";
import Translation from "@common/Translation";
import Alert from "@common/Alert";

type UploadStatus = "completed" | "failed" | "failedformat" | "cancelled" | "";

const getAlertType = (
  status: UploadStatus,
): "success" | "danger" | "info" | "" => {
  switch (status) {
    case "completed":
      return "success";
    case "failed":
    case "failedformat":
      return "danger";
    case "cancelled":
      return "info";
    default:
      return "";
  }
};

const getAlertMessage = (status: UploadStatus): React.ReactNode => {
  switch (status) {
    case "completed":
      return <Translation translate="MYTD_uploadSuccessMsg" />;
    case "failed":
      return <Translation translate="MYTD_uploadErrorMsg" />;
    case "failedformat":
      return <Translation translate="MYTD_fileUploadFormatErr" />;
    case "cancelled":
      return <Translation translate="MYTD_uploadCancelMsg" />;
    default:
      return "";
  }
};

interface UploadAlertProps {
  status: UploadStatus;
}

const UploadAlert: React.FC<UploadAlertProps> = ({ status }) => (
  <div>
    <Alert type={getAlertType(status)}>{getAlertMessage(status)}</Alert>
  </div>
);

export default UploadAlert;
