import React from "react";
import Moment from "react-moment";

import { getCookie, localeFixerMoment } from "@helpers/utilities";
import Translation from "@common/Translation";

const momentLocaleFix = localeFixerMoment(getCookie("culture"));

export function codeMap(distData) {
  const expirationDate = (
    <Moment locale={momentLocaleFix} format="l">
      {distData.expiration}
    </Moment>
  );

  if (distData.hasToken && distData.isValid) {
    return (
      <>
        <Translation translate="Validated" textonly="true" />
        <Translation translate="Expires" textonly="true" />
        {expirationDate}
      </>
    );
  }

  const codeMessages = {
    0: <Translation translate="Dist_Code_0" textonly="true" />,
    1: <Translation translate="Dist_Code_1" textonly="true" />,
    2: <Translation translate="Dist_Code_2" textonly="true" />,
    3: <Translation translate="Dist_Code_3" textonly="true" />,
    4: <Translation translate="Dist_Code_4" textonly="true" />,
    5: <Translation translate="Dist_Code_5" textonly="true" />,
    6: <Translation translate="Dist_Code_6" textonly="true" />,
    error: (
      <>
        <Translation
          translate="An_error_occurred_while_processing_your_request_"
          textonly="true"
        />
        <Translation translate="For_assistance" />
      </>
    ),
    default: "",
  };

  return codeMessages[distData.code] || codeMessages.default;
}
