import React from "react";
import { Link } from "react-router-dom";

const Tile = ({
  product,
  isMoreStuff,
  showAvatar,
  showNotifications,
  avatar,
  notifications,
}) => {
  const productTileConfig = product.Tile || {};
  const partnerPortalUrl = "https://partner.tobiidynavox.com";

  let showAvatarClass = showAvatar ? "display-block-fade" : "display-none-fade";
  let showNotificationsClass = showNotifications
    ? "display-block-fade"
    : "display-none-fade";

  let avatarStyle = {
    backgroundImage: avatar
      ? `url(/api/v1/${avatar}?w=65)`
      : "url(assets/images/avatar.jpg?w=65)",
    marginBottom: "2.3em",
    marginLeft: "-0.9em",
  };

  let partnerStyle = {
    backgroundImage: "url(/assets/images/partner-portal.svg)",
    marginBottom: "2.3em",
    marginLeft: "-0.9em",
  };

  let snapStyle = {
    backgroundImage:
      isMoreStuff === "true"
        ? "url(/assets/images/td-snap-more-stuff.svg)"
        : "url(/assets/images/td-snap-my-stuff.svg)",
    marginBottom: "2.3em",
    marginLeft: "-0.9em",
  };

  return (
    <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 fade-block fade-in">
      {productTileConfig.type === "product" &&
        productTileConfig.title !== "TD Snap" && (
          <Link
            to={
              isMoreStuff
                ? productTileConfig.moreStuffUrl
                : productTileConfig.url
            }
          >
            <div className={`tile ${productTileConfig.colorClass} tileBG`}>
              <div className="title">
                <h4>{productTileConfig.title}</h4>
              </div>
              <div className="fade-block fade-in">
                <span
                  className={`td-icon icon icon-68 icon-logo-${productTileConfig.iconClass}`}
                ></span>
              </div>
            </div>
          </Link>
        )}
      {productTileConfig.type === "account" && (
        <Link to={productTileConfig.url}>
          <div className={`tile ${productTileConfig.colorClass} tileBG`}>
            <div className="title">
              <h4>{productTileConfig.title}</h4>
            </div>
            <div className={showAvatarClass}>
              <div
                style={avatarStyle}
                className="td-icon img-circle img-circle-65 pdd-avatar"
              ></div>
            </div>
          </div>
        </Link>
      )}
      {productTileConfig.type === "notifications" && (
        <Link to={productTileConfig.url} className={showNotificationsClass}>
          <div className={`tile ${productTileConfig.colorClass} tileBG`}>
            {notifications > 0 && <div className="clown-nose"></div>}
            <div className="title">
              <h4>{productTileConfig.title}</h4>
            </div>
            <div>
              <div
                className={`notification-balloon icon icon-68 icon-logo-${productTileConfig.iconClass}`}
              >
                <div className="notifications-int">{notifications}</div>
              </div>
            </div>
          </div>
        </Link>
      )}
      {productTileConfig.type === "partner" && (
        <a href={partnerPortalUrl} target="_blank" rel="noreferrer">
          <div className={`tile ${productTileConfig.colorClass} tileBG`}>
            <div className="title">
              <h4>{productTileConfig.title}</h4>
            </div>
            <div className="display-block-fade">
              <div
                className="td-icon img-circle-70 pdd-avatar"
                style={partnerStyle}
              ></div>
            </div>
          </div>
        </a>
      )}
      {productTileConfig.type === "product" &&
        productTileConfig.title === "TD Snap" && (
          <Link
            to={
              isMoreStuff
                ? productTileConfig.moreStuffUrl
                : productTileConfig.url
            }
          >
            <div className={`tile ${productTileConfig.colorClass} tileBG`}>
              <div className="title">
                <h4>{productTileConfig.title}</h4>
              </div>
              <div className="display-block-fade">
                <div
                  className="td-icon img-circle-70 pdd-avatar"
                  style={snapStyle}
                ></div>
              </div>
            </div>
          </Link>
        )}
    </div>
  );
};

export default Tile;
