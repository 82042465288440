import React from "react";
import Translation from "@common/Translation";

export const fileUploadConfig = {
  Compass: {
    Pageset: {
      fileFormat: ".bzf, .osk, .pak",
      pageTitle: <Translation translate="MYTD_uploadPagesetLbl" />,
      returnText: <Translation translate="MYTD_ViewPagesetsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
    Page: {
      fileFormat: ".pak",
      pageTitle: <Translation translate="MYTD_UploadPageLbl" />,
      returnText: <Translation translate="MYTD_ViewPagesLbl" />,
      helpText: <Translation translate="MYTD_fileTypesAllowedMsg" />,
    },
    ScreenKeyboard: {
      fileFormat: ".osk",
      pageTitle: <Translation translate="MYTD_UploadScrKeybLbl" />,
      returnText: <Translation translate="MYTD_ViewScrKeybsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  Communicator: {
    Pageset: {
      fileFormat: ".cdd, .cddx",
      pageTitle: <Translation translate="MYTD_uploadPagesetLbl" />,
      returnText: <Translation translate="MYTD_ViewPagesetsLbl" />,
      helpText: <Translation translate="MYTD_fileTypesAllowedMsg" />,
    },
    Backup: {
      fileFormat: ".vse",
      pageTitle: <Translation translate="MYTD_uploadBackupLbl" />,
      returnText: <Translation translate="MYTD_ViewBackupsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  SymbolMate: {
    Board: {
      fileFormat: ".smd",
      pageTitle: <Translation translate="MYTD_uploadBoardLbl" />,
      returnText: <Translation translate="MYTD_ViewBoardsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  Snap: {
    Pageset: {
      fileFormat: ".sps, .spb",
      pageTitle: <Translation translate="MYTD_uploadPagesetLbl" />,
      returnText: <Translation translate="MYTD_ViewPagesetsLbl" />,
      helpText: <Translation translate="MYTD_fileTypesAllowedMsg" />,
    },
    Backup: {
      fileFormat: ".sub",
      pageTitle: <Translation translate="MYTD_uploadBackupLbl" />,
      returnText: <Translation translate="MYTD_ViewBackupsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  SnapScene: {
    Backup: {
      fileFormat: ".ssp",
      pageTitle: <Translation translate="MYTD_uploadBackupLbl" />,
      returnText: <Translation translate="MYTD_ViewBackupsLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  ALL: {
    Student: {
      fileFormat: ".auf",
      pageTitle: <Translation translate="MYTD_UploadStBkLbl" />,
      returnText: <Translation translate="MYTD_ViewStBkLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
    Book: {
      fileFormat: ".abf",
      pageTitle: <Translation translate="MYTD_UploadBookBkLbl" />,
      returnText: <Translation translate="MYTD_ViewBookBkLbl" />,
      helpText: <Translation translate="MYTD_fileTypeAllowedMsg" />,
    },
  },
  Avatar: {
    Image: {
      fileFormat: ".jpg, .jpeg, .png, .gif",
      pageTitle: <Translation translate="MYTD_uploadBackupLbl" />,
      returnText: ".jpg, .jpeg, .png, or .gif",
      helpText: <Translation translate="MYTD_fileTypesAllowedMsg" />,
    },
  },
};
