import { fetchData } from "./base-service";
import { putData } from "./base-service";
import { postData } from "./base-service";

const SUBSCRIPTIONS_API_ENDPOINTS = {
  startSnapTrial: (product) =>
    `/v1/subscriptions/start-snap-trial?product=${product}`,
  snapTrials: (langCode) => `/v1/subscriptions/snap-trials?uic=${langCode}`,
  professionalAffiliations: (langCode) =>
    `/v1/subscriptions/professional-affiliations?uic=${langCode}`,
  verifyProfessionals: () => `/v1/subscriptions/verify-professional`,
};
export const getStartSnapTrialData = async (product) =>
  putData(SUBSCRIPTIONS_API_ENDPOINTS.startSnapTrial(product));
export const getSnapTrialsData = async (langCode) =>
  fetchData(SUBSCRIPTIONS_API_ENDPOINTS.snapTrials(langCode));
export const getProfessionalAffiliations = async (langCode) =>
  fetchData(SUBSCRIPTIONS_API_ENDPOINTS.professionalAffiliations(langCode));
export const postVerifyProfessionals = async (payload) => {
  return postData(SUBSCRIPTIONS_API_ENDPOINTS.verifyProfessionals(), payload);
};
