import { createAction } from "@reduxjs/toolkit";
import { getTranslations } from "@services";
import { AppDispatch } from "store";

export interface TranslationsPayload {
  [key: string]: string;
}

export const fetchTranslationsRequest = createAction(
  "translations/fetchRequest",
);

export const fetchTranslationsSuccess = createAction<TranslationsPayload>(
  "translations/fetchSuccess",
);

export const fetchTranslationsFailure = createAction<string>(
  "translations/fetchFailure",
);

export const fetchTranslations =
  (langCode: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchTranslationsRequest());
    try {
      const response = await getTranslations(langCode);
      const { data } = response;
      dispatch(fetchTranslationsSuccess(data as TranslationsPayload));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An error occurred";
      dispatch(fetchTranslationsFailure(errorMessage));
    }
  };
