import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import {
  getImageSrc,
  determineImagePath,
  formatBytes,
  momentLocaleFix,
} from "@helpers/utilities";
import { getBackupById } from "@services";
import Translation from "@common/Translation";
import PagesetNote from "./PagesetNote";
import PagesetEditForm from "./PagesetEditForm";
import Share from "./Share";

const BackupDetails = () => {
  const { id, product } = useParams();
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);

  const [backup, setBackup] = useState({});
  const [editableFields, setEditableFields] = useState({
    title: "",
    description: "",
  });
  const [showEdit, setShowEdit] = useState(false);

  const imagePath = determineImagePath(backup.imageUrl, backup.software);
  const imageSource = getImageSrc(backup.software, imagePath);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBackupById(languageCode, id, product);
      setBackup(data);
      setEditableFields({
        title: data.title || "",
        description: data.description || "",
      });
    };
    fetchData();
  }, [languageCode, id, product]);

  const handleEditClick = (e) => {
    e.preventDefault();
    setShowEdit(true);
  };

  return (
    <div className="psc-details">
      <div className="row psc-details-info">
        <div className="col-md-5 col-md-push-7 details">
          <h2>
            <Translation translate="MYTD_BackupLbl" textonly="true" />{" "}
            <Translation translate="MYTD_detailsLbl" textonly="true" />
          </h2>

          <div className="table-responsive">
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>
                    <Translation translate="Date" textonly="true" />
                  </td>
                  <td>
                    {backup.backupDateUtc ? (
                      <Moment locale={momentLocaleFix} format="LL">
                        {backup.backupDateUtc}
                      </Moment>
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translation translate="Size" textonly="true" />
                  </td>
                  <td>
                    {backup.fileSize ? (
                      formatBytes(backup.fileSize)
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translation translate="Software" textonly="true" />
                  </td>
                  <td>
                    {backup.product ? (
                      backup.product
                    ) : (
                      <Translation translate="Not_Available" textonly="true" />
                    )}
                  </td>
                </tr>

                {showEdit ? (
                  <tr>
                    <td colSpan="2" className="form-cell">
                      <hr />
                      <PagesetEditForm
                        shouldPSC={() => false}
                        editableFields={editableFields}
                        setEditableFields={setEditableFields}
                        translations={translations}
                        handleCancelClick={() => setShowEdit(false)}
                        product={product}
                        id={id}
                        setPageset={setBackup}
                        type="backup"
                      />
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td colSpan="2">
                        <hr />
                        <div style={{ textAlign: "right" }}>
                          <a href="#" onClick={handleEditClick}>
                            <Translation translate="Edit" textonly="true" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Translation translate="Title" textonly="true" />
                      </td>
                      <td>
                        {backup.title ? (
                          backup.title
                        ) : (
                          <Translation
                            translate="Not_Available"
                            textonly="true"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Translation translate="Description" textonly="true" />
                      </td>
                      <td>
                        {backup.description ? (
                          backup.description
                        ) : (
                          <i>
                            <Translation
                              translate="Not_Available"
                              textonly="true"
                            />
                          </i>
                        )}
                      </td>
                    </tr>
                    <Share
                      translations={translations}
                      type="Backup"
                      product={product}
                      id={id}
                    />
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-6 col-md-pull-5 hidden-xs hidden-sm">
          <div className="well text-center">
            <img src={imageSource} alt="" />
          </div>
          <p className="description">
            <PagesetNote product={product} itemtype="backups" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default BackupDetails;
