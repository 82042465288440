import React from "react";
import Translation from "../../../common/Translation";
const PrintBtn = ({ handlePrint }) => {
  return (
    <button className="btn btn-lg pull-right print-order" onClick={handlePrint}>
      <Translation translate="MYTD_printLbl" />
    </button>
  );
};

export default PrintBtn;
