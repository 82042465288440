import React from "react";
import { useSelector } from "react-redux";
import { redirectToLogin } from "@services";
import { getStoreLink } from "@services";

const BuyButton = ({
  product,
  className,
  imgSrc,
  altText,
  text,
  trial = false,
}) => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const storeUrl = getStoreLink(product, trial);
  return (
    <a
      href={storeUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      onClick={(event) => {
        if (!isAuthenticated) {
          event.preventDefault();
          redirectToLogin();
        }
      }}
    >
      <img src={imgSrc} alt={altText} />
      {text}
    </a>
  );
};

export default BuyButton;
