import React, { useEffect, useState } from "react";
import { Views } from "react-big-calendar";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { getUserDevices, getUsageData, exportUsageData } from "@services";
import CalendarComponent from "./Calendar";
import MessagesList from "./MessagesList";
import Translation from "@common/Translation";
import DataSpinner from "@common/DataSpinner";
import ReportsModal from "./ReportsModal";
import { addHexToArray, getTimePeriod } from "./utils";

const Reports = () => {
  const [reportsData, setReportsData] = useState({});
  const [userDevices, setUserDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModeling, setIsModeling] = useState(false);
  const [selectedDeviceOption, setSelectedDeviceOption] = useState(null);
  const [dateChoice, setDateChoice] = useState(new Date());
  const [viewChoice, setViewChoice] = useState(Views.MONTH);
  const [selectedModelingOption, setSelectedModelingOption] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const modelingOptions = [
    { label: "Button Usage Count", value: "button" },
    { label: "Modeling Count", value: "model" },
  ];

  useEffect(() => {
    if (dateChoice !== null) {
      fetchAndUpdateReportsData();
    }
  }, [dateChoice]);

  useEffect(() => {
    if (selectedDeviceOption !== null) {
      fetchAndUpdateReportsData();
    }
  }, [selectedDeviceOption]);

  useEffect(() => {
    if (isModeling !== null) {
      fetchAndUpdateReportsData();
    }
  }, [isModeling]);

  useEffect(() => {
    if (viewChoice !== null) {
      fetchAndUpdateReportsData();
    }
  }, [viewChoice]);

  const fetchAndUpdateReportsData = async () => {
    /*   In order to get response for this api locally, you need to set up the real TDIM instead of emulator, or use the fake SnapApiService (see line 74 in backend/src/API/Configurations/ExternalServices.cs).
These API:s call Snap API that needs an authenticated user, that could be only granted by the real TDIM. */
    try {
      let { start, end } = getTimePeriod(dateChoice, viewChoice);
      const data = await getUsageData({
        start: start,
        end: end,
        view: viewChoice === Views.MONTH ? "Month" : "Day",
        isModeling: isModeling,
        snapUserId:
          selectedDeviceOption !== null
            ? selectedDeviceOption.value.snapUserId
            : null,
        deviceId:
          selectedDeviceOption !== null
            ? selectedDeviceOption.value.deviceId
            : null,
      });
      setReportsData(data);
    } catch (error) {
      console.error("Failed to get user data");
    } finally {
      if (setIsLoading) setIsLoading(false);
    }
  };

  const fetchUserDevices = async () => {
    /*   In order to get response for this api locally, you need to set up the real TDIM instead of emulator, or use the fake SnapApiService (see line 74 in backend/src/API/Configurations/ExternalServices.cs).
These API:s call Snap API that needs an authenticated user, that could be only granted by the real TDIM. */
    try {
      const data = await getUserDevices();
      const formattedOptions = data.map((item) => ({
        value: { snapUserId: item.snapUserId, deviceId: item.deviceId },
        label: item.snapUserDisplayName + " on " + item.deviceName,
      }));
      setUserDevices(formattedOptions);
    } catch (error) {
      console.error("Failed to get user devices");
    } finally {
      if (userDevices && userDevices.length > 0) {
        setSelectedDeviceOption(userDevices[0]);
      }
    }
  };

  const fetchDownloadUrl = async () => {
    let { start, end } = getTimePeriod(dateChoice, viewChoice);
    const data = await exportUsageData({
      start: start,
      end: end,
      view: viewChoice === Views.MONTH ? "Month" : "Day",
      isModeling: isModeling,
      snapUserId:
        selectedDeviceOption !== null
          ? selectedDeviceOption.value.snapUserId
          : null,
      deviceId:
        selectedDeviceOption !== null
          ? selectedDeviceOption.value.deviceId
          : null,
    });
    if (data !== "") {
      let link = document.createElement("a");
      link.href = data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Filed fetching export link");
    }
  };

  useEffect(() => {
    fetchUserDevices();
    fetchAndUpdateReportsData();
    if (modelingOptions && modelingOptions.length > 0) {
      setSelectedModelingOption(modelingOptions[0]);
    }
  }, []);

  let formattedEvents = [];
  if (reportsData.countByDateTime && reportsData.countByDateTime.length > 0) {
    formattedEvents = addHexToArray(reportsData.countByDateTime, viewChoice);
  }

  const handleModelingChange = (option) => {
    setSelectedModelingOption(option);
    setIsModeling(option.value === "model");
  };

  const handleExport = () => {
    try {
      fetchDownloadUrl();
    } catch (error) {
      console.error("Failed to get user data");
    }
  };

  const handleDeviceChange = (selectedDeviceOption) => {
    setSelectedDeviceOption(selectedDeviceOption);
  };

  const handleRefresh = () => {
    fetchAndUpdateReportsData();
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <DataSpinner />
      ) : (
        <>
          <div className="row margin-top-lg">
            <div className="col-sm-6 col-md-4 top-contain">
              <div className="row">
                <div className="col-xs-9 select-hold">
                  <Select
                    value={selectedDeviceOption}
                    onChange={handleDeviceChange}
                    options={userDevices}
                    className="device-drop"
                    placeholder={
                      <Translation translate="Select" textonly="true" />
                    }
                  />
                </div>
                <div className="col-xs-3 refresh-hold">
                  <Button
                    onClick={(e) => handleRefresh(e)}
                    className="form-btn refresh-btn"
                  >
                    <i className="icon-btn-refresh" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="sm-heading-message col-sm-6 col-md-4 margin-top-sm margin-bottom-md col-md-offset-4">
              <Translation translate="What_is_a_usage_report" textonly="true" />{" "}
              <a href="#" onClick={() => setShowModal(true)} className="">
                <Translation translate="Learn_More" textonly="true" />
              </a>
            </div>
          </div>
          <CalendarComponent
            events={formattedEvents}
            viewChoice={viewChoice}
            dateChoice={dateChoice}
            handleModelingChange={handleModelingChange}
            selectedModelingOption={selectedModelingOption}
            isModeling={isModeling}
            modelingOptions={modelingOptions}
            setDateChoice={setDateChoice}
            setViewChoice={setViewChoice}
          />
          {!!reportsData.countByMessage?.length && (
            <>
              <MessagesList messages={reportsData.countByMessage} />
              <div className="margin-top-md">
                <button
                  aria-label="Download"
                  onClick={(e) => {
                    e.preventDefault();
                    handleExport();
                  }}
                  className="btn btn-lg btn-primary"
                >
                  <Translation translate="Download" />
                </button>
              </div>
            </>
          )}
        </>
      )}
      <ReportsModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Reports;
