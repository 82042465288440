import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TrialText = ({
  trialPeriodText,
  trialPeriod,
  trialPeriodEndedText,
  trialDaysRemainingText,
  trialLink,
  trialToken,
}) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const formattedTrialString = trialPeriodText.replace("{0}", trialPeriod);
  const formattedTrialEndedString = trialPeriodEndedText.replace(
    "{0}",
    trialPeriod,
  );

  const renderTrialLink = () => {
    if (!isAuthenticated || trialToken === undefined) {
      return <Link to={trialLink}>{formattedTrialString}</Link>;
    }

    if (trialToken && !trialToken.active) {
      return <p>{formattedTrialEndedString}</p>;
    }

    const daysRemaining = trialToken?.timeLeft;
    return (
      <Link to={trialLink}>
        {daysRemaining
          ? `${trialDaysRemainingText} - ${daysRemaining}`
          : formattedTrialString}
      </Link>
    );
  };

  return <small>{renderTrialLink()}</small>;
};

export default TrialText;
