import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import TabLink from "@common/TabLink/Index";
import Translation from "@common/Translation";
import Breadcrumb from "@common/Breadcrumb";

const TAB_ROUTES = [
  { path: "/myaccount", label: "MYTD_profileLbl", end: true },
  { path: "/myaccount/usage", label: "Usage_Reports" },
  { path: "/myaccount/subscriptions", label: "MYTD_subscriptionsLbl" },
  { path: "/myaccount/licenses", label: "MYTD_licensesLbl" },
  { path: "/myaccount/orders", label: "MYTD_ordersLbl" },
];

export const MyAccount = () => {
  const { translations } = useSelector((state) => state.translations);
  const location = useLocation();

  const getBreadcrumbItems = () => {
    const baseItems = [
      {
        href: "/myaccount",
        label: translations.MYTD_MyAccountLbl,
      },
    ];

    const currentTab = TAB_ROUTES.find(
      (route) => location.pathname === route.path,
    );

    if (currentTab) {
      baseItems.push({ label: translations[currentTab.label] });
    }

    return baseItems;
  };

  if (!translations) return null;

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>{translations.MYTD_MyAccountLbl}</h1>
          <Breadcrumb items={getBreadcrumbItems()} />
        </div>
      </div>
      <div className="container-full">
        <div className="container" style={{ paddingBottom: 0 }}>
          <div className="tab-link-set">
            {TAB_ROUTES.map((route) => (
              <TabLink key={route.path} to={route.path} end={route.end}>
                <Translation translate={route.label} />
              </TabLink>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MyAccount;
