import React from "react";
import Translation from "../../../common/Translation";

const SubscriptionHeader = ({ subItem }) => (
  <h3>
    {subItem.productName + " "}
    {subItem.subscriptionTypeId === 1 ? (
      <Translation translate="MYTD_trialLbl" />
    ) : subItem.subscriptionTerm === "Yearly" ? (
      <Translation translate="yearly" />
    ) : subItem.subscriptionTerm === "Monthly" ? (
      <Translation translate="Monthly_lbl" />
    ) : (
      subItem.subscriptionTerm
    )}
    <Translation translate="MYTD_subscriptionLbl" />
  </h3>
);

export default SubscriptionHeader;
