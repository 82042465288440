import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Translation from "@common/Translation";
import { getUserLicenseKeys, generateLicenseKey } from "@services";

const Product = ({ product }) => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [keyList, setKeyList] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(null);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const keys = await getUserLicenseKeys(languageCode);
        setKeyList(keys);
      } catch (error) {
        console.error("Failed to fetch license keys:", error);
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchKeys();
  }, [languageCode]);

  const handleGenerateKeyClick = async (product) => {
    setLoadingProduct(product);
    try {
      const data = await generateLicenseKey(product);
      setKeyList((prev) =>
        prev.map((key) =>
          key.productName === `${product} (SLP)` ? { ...key, ...data } : key,
        ),
      );
    } catch (error) {
      console.error("Failed to generate license key:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    } finally {
      setLoadingProduct(null);
    }
  };

  const renderKeyComponent = (license, translationKey, product) => {
    if (loadingProduct === product) {
      return (
        <div className="key-loader">
          <i className="fa fa-refresh fa-spin"></i>
        </div>
      );
    }

    return license ? (
      <span>
        <Translation translate={translationKey} /> <br /> {license.licenseKey}
      </span>
    ) : (
      <button
        className="btn-link"
        onClick={() => handleGenerateKeyClick(product)}
      >
        <Translation translate="MYTD_generateLicenseBtnTxt" />
      </button>
    );
  };

  const { Tile, Product: ProductDetails } = product;

  const communicatorLicense = keyList.find(
    (key) => key.productName === "Communicator 5 (SLP)",
  );
  const gazeViewerLicense = keyList.find(
    (key) => key.productName === "Gaze Viewer (SLP)",
  );

  return (
    <div className="col-md-6 card fade-block fade-in">
      <div className="card-wrap" style={{ minHeight: "352px" }}>
        <div className={`card-header ${Tile.colorClass} clearfix`}>
          <div
            className={`td-icon icon icon-68 ${
              Tile.title === "Snap" ? "" : `icon-logo-${Tile.iconClass}`
            }`}
          ></div>
        </div>
        <div className="card-body">
          <h3 className="title">
            <a href={Tile.url}>{Tile.title}</a>
          </h3>
          <div className="instructions">
            <Translation translate={ProductDetails.instructions} />
          </div>
          {Tile.colorClass === "communicator" && (
            <div className="mixin-block fade-in">
              {renderKeyComponent(
                communicatorLicense,
                "MYTD_commLicenseKeyTxt",
                "Communicator",
              )}
            </div>
          )}
          {Tile.colorClass === "gaze-viewer" && (
            <div className="mixin-block fade-in">
              {renderKeyComponent(
                gazeViewerLicense,
                "gazeviewerLicenseKeyTxt",
                "GazeViewer",
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
