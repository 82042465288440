import React, { FC } from "react";
import AppRouter from "./route/app-router";
import { AuthProvider } from "./components/common/AuthContext";
import "./styles/App.css";
import { Toaster } from "react-hot-toast";

const App: FC = () => {
  return (
    <AuthProvider>
      <Toaster />
      <AppRouter />
    </AuthProvider>
  );
};

export default App;
