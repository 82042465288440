import React from "react";
import Translation from "@common/Translation";

const Heatmap = ({ eventMin, eventMax }) => {
  const min =
    !eventMin || eventMin === Number.POSITIVE_INFINITY
      ? 0
      : eventMin.toLocaleString();
  const max =
    !eventMax || eventMax === Number.NEGATIVE_INFINITY
      ? 0
      : eventMax.toLocaleString();
  const style = {
    background: "linear-gradient(90deg, #ACE9A6, #008759)",
  };

  return (
    <div className="heatmap-gradient" style={style}>
      <div className="row">
        <div className="col-md-4 event-min">{min}</div>
        <div className="col-md-4 heatmap-label">
          <Translation translate="MYTD_MBtabLblMessages" textonly="true" />
        </div>
        <div className="col-md-4 event-max">{max}</div>
      </div>
    </div>
  );
};

export default Heatmap;
