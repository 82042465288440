import Cookies from "js-cookie";

import Constants from "../utils/constant";

const Cultures = [
  "en-US", // first culture is the DEFAULT
  "es", // Spanish NEUTRAL culture
  "de", // German NEUTRAL culture
  "sv", // Swedish
  "nb", // Norwegian
  "nl", // Dutch
  "fr", // French
  "da", // Danish
  "fo", // QA
  "zh-Hans", // Chinese (Simplified)
  "pt", // Portuguese
  // 'hr', // Croatian
  "cs", // Czech
  "it", // Italian
  "is", // Icelandic
  "ar", // Arabic
  // 'he' // Hebrew
];

const languageCodes = [
  "ar",
  "en",
  "es",
  "zh-Hans",
  "de",
  "sv",
  "nb",
  "nl",
  "fr",
  "da",
  "pt",
  "cs",
  "it",
  "is",
];

export const languageToCountryMap = {
  ar: "SA", // Saudi Arabia
  en: "US", // United States
  es: "ES", // Spain
  "zh-Hans": "CN", // China
  de: "DE", // Germany
  sv: "SE", // Sweden
  nb: "NO", // Norway
  nl: "NL", // Netherlands
  fr: "FR", // France
  da: "DK", // Denmark
  pt: "PT", // Portugal
  cs: "CZ", // Czech Republic
  it: "IT", // Italy
  is: "IS", // Iceland
};

const getDefaultCulture = () => {
  return Cultures[0]; // Return the default culture
};

const getNeutralCulture = (name) => {
  // Read the first part only. E.g., "en", "es"
  return !name.includes("-") ? name : name.split("-")[0];
};

const getImplementedCulture = (name) => {
  // Make sure it's not null
  if (!name || typeof name !== "string") {
    return getDefaultCulture(); // Return the default culture
  }

  if (name === "nn") {
    name = "nb";
  }

  if (name === "no") {
    name = "nb";
  }

  // If it is implemented, accept it
  if (Cultures.some((c) => c.toLowerCase() === name.toLowerCase())) {
    return name; // Accept it
  }

  // Find a close match.
  const n = getNeutralCulture(name);
  const neutralCulture = Cultures.find((c) => c.startsWith(n));

  return neutralCulture || getDefaultCulture(); // Return the default culture as no match found
};

const cultureService = {
  setCulture: (culture) => {
    culture = getImplementedCulture(culture);
    // Save culture in a cookie
    let cookie = Cookies.get(Constants.CultureCookieName);
    if (cookie && cookie === culture) {
      // Update the cookie value
      cookie = culture;
    } else {
      // Create a new cookie
      Cookies.set(Constants.CultureCookieName, culture, {
        expires: 365, // 1 year expiration
        secure: true,
      });
      window.location.reload();
    }
  },
  getCultureOptions: () => {
    let languageCode =
      Cookies.get(Constants.CultureCookieName)?.substring(0, 2) ??
      navigator.language.substring(0, 2);
    if (languageCode == "zh") {
      languageCode = "zh-Hans";
    }
    const languageInfos = languageCodes.map((c) => {
      return {
        LanguageCode: c,
        LanguageName:
          new Intl.DisplayNames([c], { type: "language" }).of(c) || c,
      };
    });

    return {
      LanguageCode: languageCode,
      LanguageName:
        new Intl.DisplayNames([languageCode], { type: "language" }).of(
          languageCode,
        ) || languageCode,
      LanguageInfos: languageInfos,
    };
  },
};

export default cultureService;
