import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Translation from "@common/Translation";
import DeviceImage from "./DeviceImage";
import DeviceDetails from "./DeviceDetails";
import DeviceAttributes from "./DeviceAttributes";
import { getDevices } from "@services";

export const tokenLabel = (lbl, translations) => {
  const labelMap = {
    Subscription: translations.MYTD_SubscriptionLbl,
    Complimentary: translations.MYTD_ComplimentaryLbl,
    Companion: translations.MYTD_CompanionLbl,
    Trial: translations.MYTD_TrialLbl,
    Perpetual: translations.MYTD_PerpetualLbl,
    Account: translations.MYTD_AccountLbl,
  };
  return labelMap[lbl] || lbl;
};

const Devices = () => {
  const { product, translations, languageCode } = useOutletContext();
  const [devices, setDevices] = useState([]);

  const fetchDevices = async () => {
    const data = await getDevices(languageCode, product);
    setDevices(data);
  };

  useEffect(() => {
    fetchDevices();
  }, [languageCode]);

  if (devices.length <= 0) {
    return (
      <div className="col-xs-12">
        <p className="no-data-message" style={{ marginLeft: 0 }}>
          <Translation translate="MYTD_noDevicesMsg" />
        </p>
      </div>
    );
  }

  return (
    <div className="row margin-top-md">
      {devices.map((device) => {
        const speakingPermission = device.speakingPermission
          ? tokenLabel(device.speakingPermission, translations)
          : null;

        return (
          <div
            className="col-xs-12 pageset-block-col fade-block fade-in"
            key={device.id}
          >
            <div className="well well-lg well-device clearfix">
              <div className="row device-specs">
                <DeviceImage imageUrl={device.imageUrl} />
                <div className="col-xs-12">
                  {product === "compass" ? (
                    <h3>
                      {device.softwareTitle || ""}{" "}
                      <Translation translate="MYTD_forTxt" textonly="true" />{" "}
                      {device.deviceTitle}
                    </h3>
                  ) : (
                    <h3>{device.title}</h3>
                  )}
                  <DeviceDetails
                    device={device}
                    product={product}
                    languageCode={languageCode}
                    speakingPermission={speakingPermission}
                    translations={translations}
                    fetchDevices={fetchDevices}
                  />
                  <DeviceAttributes
                    device={device}
                    translations={translations}
                    tokenLabel={tokenLabel}
                    fetchDevices={fetchDevices}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Devices;
